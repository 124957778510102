<template>
    <div v-if="pageTitle" class="breadcrumbs">
        <h1 class="page__title">
            {{ pageTitle }}
        </h1>

        <ul class="breadcrumb">
            <li class="breadcrumb__item">
                <router-link to="/">Главная</router-link>
            </li>

            <BreadcrumbItem v-for="item in breadcrumbs" :key="item.name" :item="item"/>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import BreadcrumbItem from './BreadcrumbItem.vue'

export default {
    name: 'Breadcrumbs',
    components: {
        BreadcrumbItem
    },

    computed: {
        ...mapGetters({
            pageTitle: 'getPageTitle',
            breadcrumbs: 'getBreadcrumbs'
        })
    },

    methods: {
        ...mapMutations(['clearPageTitle', 'clearBreadcrumbs'])
    }
}
</script>