<template>
    <img :src="src ? serverUrl + src : file" :class="classes" :alt="alt">
</template>

<script>
import { SERVER_URL } from '../../scripts/static'

export default {
    name: 'Image',

    props: {
        src: {
            type: String,
            default: () => ''
        },

        file: {
            type: String,
            default: () => ''
        },

        alt: {
            type: [String, Number],
            default: () => ''
        },

        classes: {
            type: String,
            default: () => ''
        }
    },

    data () {
        return {
            serverUrl: SERVER_URL
        }
    }
}
</script>