<template>
    <ModalForm action-button-text="" form-header="Удаление уведомления">
        <template #button="{ clicked }">
            <ButtonNone
                @wasClicked="clicked"
            >
                Удалить
            </ButtonNone>
        </template>

        <template #form__buttons="{clicked}">
            <div class="double__columns">
                <div class="font-size__20">Удалить уведомление?</div>
                <div></div>

                <ButtonBlue @wasClicked="send">Да</ButtonBlue>

                <ButtonRed @wasClicked="clicked">Нет</ButtonRed>
            </div>
        </template>
    </ModalForm>
</template>

<script>
import { mapActions } from 'vuex'

import ModalForm from '../ModalForm.vue'
import ButtonBlue from '../../UI/buttons/ButtonBlue.vue'
import ButtonRed from '../../UI/buttons/ButtonRed.vue'
import ButtonNone from '../../UI/buttons/ButtonNone.vue'

export default {
    name: 'DeleteNotificationModal',

    components: {
        ButtonNone,
        ButtonRed,
        ButtonBlue,
        ModalForm
    },

    props: {
        notificationId: {
            required: true,
            type: Number
        },

        notificationType: {
            required: true,
            type: String
        }
    },

    data () {
        return {
            isAwait: false
        }
    },

    methods: {
        ...mapActions(['deleteNotificationSystem', 'deleteNotificationMessages']),

        send () {
            if (!this.isAwait) {
                this.isAwait = true

                if (this.notificationType === 'system') {
                    this.deleteNotificationSystem(this.notificationId)
                        .then(() => {
                            this.isAwait = false
                        })
                        .catch(() => {
                            this.isAwait = false
                        })
                } else {
                    this.deleteNotificationMessages(this.notificationId)
                        .then(() => {
                            this.isAwait = false
                        })
                        .catch(() => {
                            this.isAwait = false
                        })
                }
            }
        }
    }
}
</script>