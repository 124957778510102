<template>
    <input type="checkbox" :id="name" :name="name" @change="updateValue" :checked="checked ?? false">
    <label :for="name" class="interactive__checkbox"></label>
</template>

<script>
export default {
    name: 'InputCheckbox',
    props: {
        modelValue: {
            type: Boolean
        },
        name: {
            type: String,
            required: true
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateValue (event) {
            if (event.target.checked) {
                this.$emit('update:modelValue', true)
            } else {
                this.$emit('update:modelValue', false)
            }
        }
    }
}
</script>

<style scoped>

</style>