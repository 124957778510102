
export const APP_ENV = process.env.VUE_APP_ENV
export const DOMAIN = process.env.VUE_APP_DOMAIN
export const SERVER_URL = process.env.VUE_APP_SERVER_URL
export const SERVER_API = process.env.VUE_APP_SERVER_URL + '/api/v' + process.env.VUE_APP_SERVER_API_VERSION

export const SORT_ITEM_DEFAULT_OPTION = 'all'
export const STATUS_TICKET_DEFAULT_OPTION = 'all'
export const statusTicketOptions = () => {
    return [
        {
            text: 'Все',
            value: 'all'
        },
        {
            text: 'Открытые',
            value: 'active'
        },
        {
            text: 'Закрытые',
            value: 'close'
        }
    ]
}
export const sortItemsOptions = () => {
    return [
        {
            text: 'Все',
            value: 'all'
        },
        {
            text: 'Открытые',
            value: 'active'
        },
        {
            text: 'Закрытые',
            value: 'close'
        }
    ]
}
