<template>
    <ModalForm action-button-text="" form-header="Вход">
        <template #button="{clicked}">
            <span class="pointer" @click="clicked">Вход</span>
        </template>

        <div class="grid__gap__30">
            <div class="form__input">
                <div class="input__header">
                    E-mail
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="email"
                    type-input="email"
                    :errors="validateErrors.email"
                />
            </div>

            <div class="form__input">
                <div class="input__header">
                    Пароль
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="password"
                    type-input="password"
                    :errors="validateErrors.password"
                />
            </div>
        </div>

        <template #form__buttons="{}">
            <div class="double__columns__right">
                <ButtonGreen @wasClicked="send">Войти</ButtonGreen>

                <RecoveryPasswordForm @wasclicked="open"/>
            </div>
        </template>
    </ModalForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import RecoveryPasswordForm from './RecoveryPasswordForm.vue'
import ModalForm from '../../Modals/ModalForm.vue'
import ButtonGreen from '../../UI/buttons/ButtonGreen.vue'
import InputText from '../../UI/input/InputText.vue'
import RequiredInput from '../../UI/input/RequiredInput.vue'

export default {
    name: 'LoginForm',

    components: {
        RequiredInput,
        InputText,
        ButtonGreen,
        ModalForm,
        RecoveryPasswordForm,
    },

    computed: {
        ...mapGetters({
            validateErrors: 'getValidateErrors'
        })
    },

    data () {
        return {
            isAwait: false,
            openedModal: false,
            email: null,
            password: null,
        }
    },

    methods: {
        ...mapActions(['login']),

        open () {
            this.openedModal = !this.openedModal

            if (this.openedModal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        },

        send () {
            if (!this.isAwait) {
                this.isAwait = true;

                this.login({ email: this.email, password: this.password })
                    .then(() => {
                        document.body.style.overflow = 'auto'
                    })
                    .catch(() => {
                        this.isAwait = false
                    })
            }
        }
    }
}
</script>
