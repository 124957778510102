<template>
    <button
        class="button"
        :class="colorButton"
        @click="$emit('wasClicked')"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'Button',

    props: {
        colorButton: {
            type: String,
        }
    }
}
</script>

<style scoped>

</style>