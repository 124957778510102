import { get, post } from '../../scripts/ajax'
import { mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        artistDeleteBids: [],

        users: [],
        usersPersonalData: []
    },

    mutations: {
        setArtistDeleteBids: mutationWithPaginate('artistDeleteBids'),
        updateArtistDeleteBid (state, artistDeleteBid) {
            let index = state.artistDeleteBids.findIndex(item => item.id === artistDeleteBid.id);

            if (index !== -1) {
                state.artistDeleteBids[index] = artistDeleteBid;
            }
        },

        setUsers: mutationWithPaginate('users'),
        setUsersPersonalData: mutationWithPaginate('usersPersonalData'),

        clearUsers: state => state.users = [],
        clearUsersPersonalData: state => state.usersPersonalData = []
    },

    getters: {
        getArtistDeleteBids: state => state.artistDeleteBids,

        getUsers: state => state.users,
        getUsersPersonalData: state => state.usersPersonalData
    },

    actions: {
        async loadUsers (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/users', data, {}, true)
                    .then(response => {
                        ctx.commit('setUsers', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorUsers', response.data.paginator.next_cursor)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadDashboardArtists (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/artists', data, {}, true)
                    .then(response => {
                        ctx.commit('setArtists', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorArtists', response.data.paginator.next_cursor)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadUserPersonalDataProcess (ctx, userPersonalDataId) {
            return new Promise((resolve, reject) => {
                get('/dashboard/personal-data/' + userPersonalDataId, {}, {}, true)
                    .then(response => {
                        ctx.commit('setPersonal', response.data.personal)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadUsersPersonalDataProcess (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/personal-data/process', data.params, {}, true)
                    .then(response => {
                        ctx.commit('setUsersPersonalData', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorUsersPersonalData', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadUsersPersonalDataSuccess (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/personal-data/successes', data.params, {}, true)
                    .then(response => {
                        ctx.commit('setUsersPersonalData', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorUsersPersonalData', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadUsersPersonalDataCanceled (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/personal-data/canceled', data.params, {}, true)
                    .then(response => {
                        ctx.commit('setUsersPersonalData', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorUsersPersonalData', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadArtistDeleteBids (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/dashboard/artist-delete-bids', data.params, {}, true)
                    .then(response => {
                        ctx.commit('setArtistDeleteBids', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorUsersPersonalData', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async validateUserPersonalData (ctx, userPersonalDataId) {
            return new Promise((resolve, reject) => {
                post('/dashboard/personal-data/' + userPersonalDataId + '/validate', {}, {}, true)
                    .then(response => {
                        ctx.commit('setPersonal', response.data.personal)
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async cancelValidateUserPersonalData (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/dashboard/personal-data/' + data.data_id + '/cancel', data, {}, true)
                    .then(response => {
                        ctx.commit('setPersonal', response.data.personal)
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async completeArtistDeleteBid (ctx, id) {
            return new Promise((resolve, reject) => {
                post('/dashboard/artist-delete-bids/' + id, {}, {}, true)
                    .then(response => {
                        ctx.commit('updateArtistDeleteBid', response.data.artist_delete_bid)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
