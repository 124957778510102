<template>
    <Icon icon="arrow__right"/>
</template>

<script>
import Icon from './Icon'

export default {
    name: 'IconArrowRight',

    components: {
        Icon
    }
}
</script>

<style scoped>

</style>