<template>
    <HeaderMain/>

    <FlashMessage/>

    <Content>
        <router-view/>
    </Content>

    <Cookie/>

    <Footer/>
</template>

<script>
import { mapActions } from 'vuex'

import FlashMessage from '../components/modals/FlashMessage.vue'
import Footer from '../components/Main/Footer.vue'
import Content from '../components/Content.vue'
import HeaderMain from '../components/Main/HeaderMain.vue'
import Cookie from '../components/Main/Cookie.vue';

export default {
    name: 'MainLayout',

    components: {
        Cookie,
        HeaderMain,
        Content,
        Footer,
        FlashMessage
    },

    props: {
        usePageLayout: {
            type: Boolean
        }
    },

    methods: {
        ...mapActions(['authorise'])
    },

    mounted () {
        this.authorise()
    }
}
</script>
