import { mutation } from '../../scripts/scripts'

export default {
    state: {
        pageTitle: null,
        breadcrumbs: []
    },

    mutations: {
        setPageTitle: mutation('pageTitle'),
        setBreadcrumbs: mutation('breadcrumbs'),

        clearPageTitle: state => state.pageTitle = null,
        clearBreadcrumbs: state => state.breadcrumbs = []
    },

    getters: {
        getPageTitle: state => state.pageTitle,
        getBreadcrumbs: state => state.breadcrumbs
    }
}
