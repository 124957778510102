<template>
    <div :class="!notification.is_viewed ? 'not__viewed' : ''" class="notification shadow__box">

        <div class="double__columns">
            {{ notification.created_at }}

            <div class="grid__content__right">
                <div v-if="notification.is_viewed" class="text__green">
                    <IconCheck/>

                    Прочитано
                </div>
                <div v-else class="pointer" @click="view(notification)">
                    Прочитано
                </div>
            </div>
        </div>

        <div v-if="notification.type === 'validate_personal_data'" class="notification__desc">
            Персональные данные были успешно проверены. На указанный в профиле e-mail отправлено письмо
            с офертой агентского договора.

            <br>

            Теперь вы можете создать страницу художника и начать публикацию своих работ.
        </div>
        <div v-if="notification.type === 'cancel_validate_personal_data'" class="notification__desc">
            Ошибка проверки персональных данных.
        </div>

        <div v-if="notification.type === 'create_deal'" class="notification__desc">
            Создана сделка

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>

            для продажи изображения
        </div>

        <div v-show="notification.type === 'process_cancel_deal'" class="notification__desc">

            {{
                (notification.model?.cancel_customer ? 'Покупатель ' : 'Художник ') + 'инициировал процедуру отмены сделки'
            }}

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>
        </div>

        <div v-show="notification.type === 'load_image_in_deal'" class="notification__desc">
            Художник загрузил изображение к сделке

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>
        </div>

        <div v-show="notification.type === 'end_deal'" class="notification__desc">
            Сделка

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>

            завершена
        </div>

        <div v-show="notification.type === 'cancel_deal'" class="notification__desc">
            Сделка

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>

            отменена
        </div>

        <div v-show="notification.type === 'create_message_in_deal_chat'" class="notification__desc">
            Получено новое сообщение в чате сделки

            <router-link :to="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                №{{ notification.model_id }}
            </router-link>
        </div>

        <div class="double__columns">
            <div v-if="notification.type === 'validate_personal_data'" class="justify__content__left">
                <ButtonBlueLink :link="'/profile/settings/artist'" @click="viewAndClose(notification)">
                    Создать станицу
                </ButtonBlueLink>
            </div>
            <div v-else-if="notification.type === 'cancel_validate_personal_data'" class="justify__content__left">
                <ButtonBlueLink :link="'/profile/personal'" @click="viewAndClose(notification)">
                    Подробнее
                </ButtonBlueLink>
            </div>
            <div v-else class="justify__content__left">
                <ButtonBlueLink :link="'/deals/' + notification.model_id" @click="viewAndClose(notification)">
                    Подробнее
                </ButtonBlueLink>
            </div>

            <div v-if="typeNotification" class="justify__content__right">
                <DeleteNotificationModal
                    :notification-id="notification.id"
                    :notification-type="typeNotification"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import IconCheck from '../UI/icons/IconCheck.vue'
import ButtonBlueLink from '../UI/buttons/ButtonBlueLink.vue'
import DeleteNotificationModal from '../Modals/Notifications/DeleteNotificationModal.vue'

export default {
    name: 'NotificationCard',

    components: {
        DeleteNotificationModal,
        ButtonBlueLink,
        IconCheck
    },

    props: {
        modelValue: {
            type: Boolean
        },

        notification: {
            required: true,
            type: Object
        },

        typeNotification: {
            type: String,
            default: () => 'system'
        }
    },

    methods: {
        ...mapActions(['viewNotificationSystem', 'viewNotificationMessages', 'existsNotViewed']),

        viewAndClose (notification) {
            if (!notification.is_viewed) {
                if (this.typeNotification === 'system') {
                    this.viewNotificationSystem(notification.id).then(() => this.existsNotViewed())
                } else {
                    this.viewNotificationMessages(notification.id).then(() => this.existsNotViewed())
                }
            }

            this.$emit('update:modelValue', false)
        },

        view (notification) {
            if (!notification.is_viewed) {
                if (this.typeNotification === 'system') {
                    this.viewNotificationSystem(notification.id).then(() => this.existsNotViewed())
                } else {
                    this.viewNotificationMessages(notification.id).then(() => this.existsNotViewed())
                }
            }
        }
    },

    unmounted () {
        document.body.style.overflow = 'auto'
    }
}
</script>