<template>
    <div class="dropdown">
        <span class="nav__icon pointer" :class="visibleContent ? 'active' : ''" @click="toggle">
            <IconMenu/>
        </span>

        <transition name="fade">
            <div v-if="visibleContent" class="dropdown__content__top grid__gap__10 shadow__box">

                <router-link class="text__dark hover__link__primary" to="/catalog">
                    Каталог
                </router-link>

                <router-link class="text__dark hover__link__primary" to="/artists">
                    Художники
                </router-link>

            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import IconMenu from '../icons/IconMenu.vue'

export default {
    name: 'DropdownMenu',

    components: {
        IconMenu
    },

    props: {
        modelValue: {
            type: Boolean
        }
    },

    computed: {
        ...mapGetters({
            authUser: 'getAuthUser'
        })
    },

    data () {
        return {
            visibleContent: false
        }
    },

    methods: {
        toggle () {
            this.visibleContent = !this.visibleContent
        }
    },

    mounted () {
        window.addEventListener('click', (event) => {
            let parent = (node) => {
                if (node?.classList?.contains('dropdown') || node?.classList?.contains('select__options')) {
                    return
                }

                if (node?.parentNode) {
                    parent(node?.parentNode)
                } else {
                    this.visibleContent = false
                }
            }

            if (this.visibleContent) {
                parent(event?.target)
            }
        })
    }
}
</script>