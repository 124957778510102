<template>
    <i :class="'icon__' + icon"></i>
</template>

<script>
export default {
    name: 'Icon',

    props: {
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>