import { createStore } from 'vuex'
import { mutation } from '../scripts/scripts'

import artist from './modules/artist'
import agent_report from './modules/agent_report'
import auth from './modules/auth'
import breadcrumbs from './modules/breadcrumbs'
import flashMessages from './modules/flash_messages'
import followers from './modules/followers'
import confirmation from './modules/confirmation'
import dashboard from './modules/dashboard'
import tags from './modules/tags'
import ticket from './modules/ticket'
import items from './modules/items'
import deal from './modules/deal'
import user from './modules/user'
import message from './modules/message'
import notification from './modules/notification'
import transaction from './modules/transaction'
import reports from './modules/reports'

import loaders from './loaders'
import cursors from './cursors'


export default createStore({
    state: {
        loadErrorPage: null,

        discountActiveOptions: [
            {
                text: 1,
                value: 1
            },
            {
                text: 2,
                value: 2
            },
            {
                text: 3,
                value: 3
            },
            {
                text: 4,
                value: 4
            },
            {
                text: 5,
                value: 5
            },
            {
                text: 6,
                value: 6
            },
            {
                text: 7,
                value: 7
            }
        ],

        searchQuery: '',

        validateErrors: []
    },

    getters: {
        getValidateErrors: state => state.validateErrors,

        loadErrorPageGetter (ctx) {
            return ctx.loadErrorPage
        },

        discountActiveOptionsGetter (ctx) {
            return ctx.discountActiveOptions
        },

        validateErrorsGetter (ctx) {
            return ctx.validateErrors
        }
    },

    mutations: {
        setValidateErrors: mutation('validateErrors'),

        loadErrorPageMutation (state, page) {
            state.loadErrorPage = page
        }
    },

    modules: {
        artist,
        agent_report,
        auth,
        breadcrumbs,
        flashMessages,
        followers,
        confirmation,
        dashboard,
        tags,
        ticket,
        items,
        deal,
        message,
        notification,
        user,
        transaction,
        reports,

        loaders,
        cursors
    }
})
