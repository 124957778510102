<template>
    <div class="modal" v-if="toggle ? modelValue : true" @click="toggleModal">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'Modal',
    props: {
        modelValue: {
            type: Boolean
        },

        toggle: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        toggleModal () {
            if (this.toggle) {
                this.$emit('click', !this.modelValue)

                if (this.modelValue) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = 'auto'
                }
            }
        }
    },

    unmounted () {
        document.body.style.overflow = 'auto'
    }
}
</script>
