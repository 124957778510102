<template>
    <ButtonLink
        color-button="blue"
        :link="link"
    >
        <slot></slot>
    </ButtonLink>
</template>

<script>
import ButtonLink from './ButtonLink'

export default {
    name: 'ButtonBlueLink',

    props: {
        link: {
            type: String,
            required: true
        }
    },

    components: {
        ButtonLink
    }
}
</script>

<style scoped>

</style>