import { createRouter, createWebHistory } from 'vue-router'
import {libraryArtistRoutes} from "./LibraryArtistRoutes";

const routes = [
        ...libraryArtistRoutes,
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Магазин обложек для книг',
            layout: 'Home',
            descriptionType: 'home',
            usePageLayout: false
        },
        component: () => import('../views/main/HomeView.vue')
    },
    {
        path: '/catalog',
        name: 'Catalog',
        meta: {
            title: 'Купить обложку для книги',
            descriptionType: 'catalog',
            layout: 'Main'
        },
        component: () => import('../views/main/CatalogView.vue')
    },
    {
        path: '/artists',
        name: 'Artists',
        meta: {
            title: 'Художники',
            descriptionType: 'artists',
            layout: 'Main'
        },
        component: () => import('../views/main/ArtistsView.vue')
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: 'Ошибка 404',
            layout: 'Main'
        },
        component: () => import('../views/errors/404View.vue')
    },
    {
        path: '/recovery-password/:token',
        name: 'RecoveryPassword',
        meta: {
            title: 'Восстановление пароля',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/main/RecoveryPasswordView.vue')
    },

    {
        path: '/items/:itemId',
        name: 'ItemView',
        meta: {
            title: 'Изображение',
            type: 'item',
            descriptionType: 'item',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/main/ItemView.vue')
    },

    {
        path: '/deals/:dealId',
        name: 'Deal',
        meta: {
            title: 'Сделка',
            type: 'deal',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/main/DealView.vue')
    },

    {
        path: '/support',
        name: 'Support',
        meta: {
            title: 'Техническая поддержка',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/support/Support.vue'),
        children: [
            {
                path: '',
                name: 'SupportUser',
                meta: {
                    title: 'Техническая поддержка'
                },
                props: true,
                component: () => import('../views/support/SupportUser.vue')
            },
            {
                path: 'tickets/:ticketId',
                name: 'Ticket',
                meta: {
                    type: 'ticket',
                    title: 'Тикет'
                },
                props: true,
                component: () => import('../views/support/Ticket.vue')
            }
        ]
    },

    {
        path: '/profile',
        name: 'Profile',
        meta: {
            title: 'Мой профиль',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/user/profile/ProfileView.vue'),
        children: [
            {
                path: 'deals',
                name: 'ProfileDeals',
                meta: {
                    title: 'Сделки',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/DealsView.vue')
            },
            {
                path: 'finance',
                name: 'ProfileFinance',
                meta: {
                    title: 'Финансы',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/FinanceView.vue')
            },
            {
                path: 'follows',
                name: 'ProfileFollows',
                meta: {
                    title: 'Подписки',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/FollowsView.vue')
            },
            {
                path: 'settings',
                name: 'ProfileSettings',
                meta: {
                    title: 'Настройки профиля',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/SettingsProfileView.vue')
            },
            {
                path: 'settings/artist',
                name: 'ArtistSettings',
                meta: {
                    title: 'Настройки художника',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/SettingsArtistView.vue')
            },
            {
                path: 'artist',
                name: 'ProfileArtist',
                meta: {
                    title: 'Страница художника',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/ArtistView.vue')
            },
            {
                path: 'documents',
                name: 'ProfileDocuments',
                meta: {
                    title: 'Документы',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/DocumentsView.vue'),
                children: [
                    {
                        path: 'reports',
                        name: 'ProfileDocumentsReports',
                        meta: {
                            title: 'Документы - отчёты',
                            layout: 'Main'
                        },
                        component: () => import('../views/user/profile/DocumentsReportsView.vue')
                    },
                    {
                        path: 'other',
                        name: 'ProfileDocumentsOther',
                        meta: {
                            title: 'Документы - прочее',
                            layout: 'Main'
                        },
                        component: () => import('../views/user/profile/DocumentsOtherView.vue')
                    }
                ]
            },
            {
                path: 'personal',
                name: 'ProfilePersonal',
                meta: {
                    title: 'Персональные данные',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/PersonalView.vue')
            },
            {
                path: 'notifications/system',
                name: 'ProfileNotificationsSystem',
                meta: {
                    title: 'Уведомления',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/NotificationsSystemView.vue')
            },
            {
                path: 'notifications/messages',
                name: 'ProfileNotificationsMessages',
                meta: {
                    title: 'Уведомления',
                    layout: 'Main'
                },
                component: () => import('../views/user/profile/NotificationsMessagesView.vue')
            }
        ]
    },

    {
        path: '/artist/:artistId',
        name: 'Artist',
        meta: {
            title: 'Страница художника',
            type: 'artist',
            layout: 'Main'
        },
        props: true,
        component: () => import('../views/user/artist/ArtistView.vue'),
        children: [
            {
                path: 'store',
                name: 'ArtistStore',
                meta: {
                    title: 'Магазин',
                    type: 'artist',
                    descriptionType: 'artistStore',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/artist/StoreView.vue')
            },
            {
                path: 'store/add',
                name: 'ArtistStoreAdd',
                meta: {
                    title: 'Добавление изображения в магазин',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/items/NewItemView.vue')
            },
            {
                path: 'store/edit/:itemId',
                name: 'ArtistStoreEdit',
                meta: {
                    title: 'Редактирование изображения',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/items/EditItemView.vue')
            },
            {
                path: 'portfolio',
                name: 'ArtistPortfolio',
                meta: {
                    title: 'Портфолио',
                    type: 'artist',
                    descriptionType: 'artistPortfolio',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/artist/PortfolioView.vue')
            },
            {
                path: 'portfolio/add',
                name: 'ArtistPortfolioAdd',
                meta: {
                    title: 'Добавление изображения в портфолио',
                    type: 'artist',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/items/NewItemPortfolioView.vue')
            },
            {
                path: 'portfolio/edit/:itemId',
                name: 'ArtistPortfolioEdit',
                meta: {
                    title: 'Редактирование изображения',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/items/EditItemPortfolioView.vue')
            },
            {
                path: 'deals',
                name: 'ArtistDeals',
                meta: {
                    title: 'Сделки',
                    type: 'artist',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/artist/DealsView.vue')
            },
            {
                path: 'followers',
                name: 'ArtistFollowers',
                meta: {
                    title: 'Подписчики',
                    type: 'artist',
                    layout: 'Main'
                },
                props: true,
                component: () => import('../views/user/artist/FollowersView.vue')
            }
        ]
    },

    {
        path: '/search',
        name: 'Search',
        meta: {
            layout: 'Main',
            title: 'Поиск'
        },
        component: () => import('../views/main/search/SearchView.vue'),
        children: [
            {
                path: 'artists',
                name: 'SearchArtists',
                meta: {
                    descriptionType: 'artists',
                },
                component: () => import('../views/main/search/ArtistsView.vue')
            },
            {
                path: 'items',
                name: 'SearchItems',
                meta: {
                    descriptionType: 'searchItems',
                },
                component: () => import('../views/main/search/ItemsView.vue')
            }
        ]
    },

    {
        path: '/offer',
        name: 'Offer',
        meta: {
            layout: 'Main',
            title: 'Публичная оферта'
        },
        component: () => import('../views/main/OfferView.vue')
    },
    {
        path: '/agent-offer',
        name: 'AgentOffer',
        meta: {
            layout: 'Main',
            title: 'Оферта агентского договора'
        },
        component: () => import('../views/main/AgentOffer.vue')
    },
    {
        path: '/rules',
        name: 'Rules',
        meta: {
            layout: 'Main',
            title: 'Правила пользования'
        },
        component: () => import('../views/main/RulesView.vue')
    },
    {
        path: '/confidential',
        name: 'Confidential',
        meta: {
            layout: 'Main',
            title: 'Политика обработки персональных данных'
        },
        component: () => import('../views/main/ConfidentialView.vue')
    },
    {
        path: '/consent-confidential',
        name: 'ConsentConfidential',
        meta: {
            layout: 'Main',
            title: 'Согласие на обработку персональных данных'
        },
        component: () => import('../views/main/ConsentConfidentialView.vue')
    },

    {
        path: '/library',
        name: 'Library',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/LibraryView.vue')
    },
    {
        path: '/library/register-and-login',
        name: 'LibraryRegisterAndLogin',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/RegisterAndLoginView.vue'),
    },
    {
        path: '/library/recovery',
        name: 'LibraryRecovery',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/RecoveryView.vue'),
    },
    {
        path: '/library/profile-delete',
        name: 'LibraryProfileDelete',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/ProfileDeleteView.vue'),
    },
    {
        path: '/library/profile-settings',
        name: 'LibraryProfileSettings',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/ProfileSettingsView.vue'),
    },
    {
        path: '/library/finances',
        name: 'LibraryFinances',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/FinancesView.vue'),
    },
    {
        path: '/library/notifications',
        name: 'LibraryNotifications',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/user/NotificationsView.vue'),
    },

    {
        path: '/library/search-image',
        name: 'LibrarySearchImage',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/customer/SearchImageView.vue'),
    },
    {
        path: '/library/deal-create',
        name: 'LibraryDealCreate',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/customer/DealCreateView.vue'),
    },
    {
        path: '/library/follows',
        name: 'LibraryFollows',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/customer/FollowsView.vue'),
    },
    {
        path: '/library/customer-deals',
        name: 'LibraryCustomerDeals',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/customer/CustomerDealsView.vue'),
    },


    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            layout: 'Dashboard',
            title: 'Панель модератора'
        },
        component: () => import('../views/dashboard/IndexView.vue'),
        children: [
            {
                path: 'login',
                name: 'DashboardLogin',
                meta: { layout: 'DashboardLogin' },
                component: () => import('../views/dashboard/LoginView.vue')
            },
            {
                path: 'users',
                name: 'DashboardUsers',
                component: () => import('../views/dashboard/UsersView.vue')
            },
            {
                path: 'artists',
                name: 'DashboardArtists',
                component: () => import('../views/dashboard/ArtistsView.vue')
            },
            {
                path: 'moderators',
                name: 'DashboardModerators',
                component: () => import('../views/dashboard/ModeratorsView.vue')
            },
            {
                path: 'personal-data',
                name: 'PersonalData',
                component: () => import('../views/dashboard/personal_data/PersonalDataView.vue')
            },
            {
                path: 'personal-data/successes',
                name: 'PersonalDataSuccesses',
                component: () => import('../views/dashboard/personal_data/PersonalDataSuccessesView.vue')
            },
            {
                path: 'personal-data/canceled',
                name: 'PersonalDataCanceled',
                component: () => import('../views/dashboard/personal_data/PersonalDataCanceledView.vue')
            },
            {
                path: 'personal-data/:userPersonalDataId',
                name: 'PersonalDataCheck',
                props: true,
                component: () => import('../views/dashboard/personal_data/PersonalDataCheckView.vue')
            },
            {
                path: 'catalog',
                name: 'DashboardCatalog',
                props: true,
                component: () => import('../views/dashboard/CatalogView.vue')
            },
            {
                path: 'orders',
                name: 'DashboardOrders',
                props: true,
                component: () => import('../views/dashboard/OrdersView.vue')
            },
            {
                path: 'bids-for-delete',
                name: 'BidsForDelete',
                props: true,
                component: () => import('../views/dashboard/BidsForDeleteView.vue')
            },
            {
                path: 'reports',
                name: 'DashboardReports',
                props: true,
                component: () => import('../views/dashboard/ReportsView.vue')
            },
            {
                path: 'tags',
                name: 'DashboardTags',
                props: true,
                component: () => import('../views/dashboard/TagsView.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active'
})

export default router
