<template>
    <slot name="button">
        <span @click="open" class="nav__icon pointer">
            <IconEnvelope/>

            <span v-show="notViewed" class="indicator"></span>
        </span>
    </slot>

    <Modal v-model="openedModal">
        <div class="notifications__container">
            <div class="container">
                <div class="container__title double__columns">
                    Сообщения

                    <div class="grid__content__right font-size__24">
                        <i class="icon__close" @click="open"> </i>
                    </div>
                </div>

                <div class="container__body grid__gap__30">
                    <router-link
                        v-if="notifications.length"
                        :to="'/profile/notifications/messages'"
                        @click="open"
                        class="all__notifications"
                    >
                        Все сообщения

                        <IconArrowRight/>
                    </router-link>

                    <Notifications
                        v-model="openedModal"
                        :notifications="notifications"
                    />
                </div>

                <div class="container__footer"></div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Modal from '../Modal.vue'
import IconArrowRight from '../../UI/icons/IconArrowRight.vue'
import Notifications from '../../Main/Notifications.vue'
import IconEnvelope from '../../UI/icons/IconEnvelope.vue'

export default {
    name: 'NotificationMessageModal',

    components: {
        IconEnvelope,
        Notifications,
        IconArrowRight,
        Modal
    },

    computed: {
        ...mapGetters({
            authUser: 'getAuthUser',
            notViewed: 'getExistsNotViewedMessages',
            notifications: 'getNotificationsMessages'
        })
    },

    data () {
        return {
            openedModal: false
        }
    },

    watch: {
        openedModal () {
            if (this.openedModal) {
                this.clearNotificationsMessages()

                this.loadNotificationsMessages({cursor: null})
            }
        }
    },

    methods: {
        ...mapActions(['loadNotificationsMessages']),
        ...mapMutations(['clearNotificationsMessages']),

        open () {
            this.openedModal = !this.openedModal

            if (this.openedModal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
    },

    async mounted () {
        window.addEventListener('click', (event) => {
            const clickElement = event?.target

            if (clickElement?.classList.contains('modal')) {
                this.openedModal = false

                document.body.style.overflow = 'auto'
            }
        })
    }
}
</script>