import { get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'
import { SERVER_API } from '../../scripts/static'

import cookie from 'vue-cookies'
import axios from 'axios'

export default {
    state: {
        deal: null,

        customerDeals: [],
        artistDeals: [],
    },

    mutations: {
        setDeal: mutation('deal'),
        setCustomerDeals: mutationWithPaginate('customerDeals'),
        setArtistDeals: mutationWithPaginate('artistDeals'),

        clearDeal: state => state.deal = null,
        clearCustomerDeals: state => state.customerDeals = [],
        clearArtistDeals: state => state.artistDeals = [],
    },

    getters: {
        getDeal: state => state.deal,
        getCustomerDeals: state => state.customerDeals,
        getArtistDeals: state => state.artistDeals
    },

    actions: {
        async loadDeal (ctx, dealId) {
            ctx.commit('setLoaderDeal', true)

            return new Promise((resolve, reject) => {
                get('/deals/' + dealId)
                    .then(response => {
                        let deal = response.data.deal
                        if (!deal) {
                            ctx.commit('loadErrorPageMutation', 404)
                        } else {
                            ctx.commit('setDeal', deal)
                        }

                        ctx.commit('setLoaderDeal', false)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadDeals (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderCustomerDeals', true)
            }

            return new Promise((resolve, reject) => {
                get('/deals/user', data.params)
                    .then(response => {
                        ctx.commit('setCustomerDeals', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorCustomerDeals', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderCustomerDeals', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadArtistDeals (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderArtistDeals', true)
            }

            return new Promise((resolve, reject) => {
                get('/deals/artist', data.params)
                    .then(response => {
                        ctx.commit('setArtistDeals', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorArtistDeals', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderArtistDeals', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async createDeal (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/deals', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setItem', response.data.item)
                        ctx.commit('setAuthUser', response.data.user)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async saveDealImage (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/deals/' + data.get('deal_id') + '/image', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setDeal', response.data.deal)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async downloadDealImage (ctx, deal) {
            return new Promise((resolve, reject) => {
                const authorization = cookie.get('authToken') ? { Authorization: 'Bearer ' + cookie.get('authToken') } : {}

                const headers = Object.assign(authorization)

                axios.get(SERVER_API + '/deals/' + deal.id + '/image/download', {
                    headers, responseType: 'blob'
                })
                    .then(response => {
                        ctx.commit('setSuccessMessage', response.data.message)

                        const href = URL.createObjectURL(response.data)

                        let link = document.createElement('a')
                        link.href = href
                        link.setAttribute('download', deal.item.name + '.' + deal.image.extension)
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setErrorMessage', error.response)

                        reject(error)
                    })
            })
        },

        async endedDeal (ctx, dealId) {
            return new Promise((resolve, reject) => {
                post('/deals/' + dealId + '/ended')
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setDeal', response.data.deal)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async cancelDeal (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/deals/' + data.deal_id + '/cancel', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setDeal', response.data.deal)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
