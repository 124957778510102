<script>
export default {
    name: 'DashboardNavigate'
}
</script>

<template>
    <div class="dashboard__navigate">
        <div class="grid__gap__10 font-size__15 text__white hover__link__white">
            <router-link to="/dashboard/statistics">Статистика</router-link>
            <router-link to="/dashboard/users">Пользователи</router-link>
            <router-link to="/dashboard/artists">Художники</router-link>
            <router-link to="/dashboard/moderators">Модераторы</router-link>
            <router-link to="/dashboard/personal-data">Перс. данные</router-link>
            <router-link to="/dashboard/catalog">Каталог</router-link>
            <router-link to="/dashboard/orders">Заказы</router-link>
            <router-link :to="{name: 'BidsForDelete'}">
                Заявки на удаление
            </router-link>
            <router-link to="/dashboard/reports">Отчёты</router-link>
            <router-link to="/dashboard/complaints">Жалобы</router-link>
            <router-link to="/dashboard/tags">Теги</router-link>
        </div>
    </div>
</template>