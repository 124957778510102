<template>
    <ModalForm action-button-text="" form-header="Восстановление доступа">
        <template #button="{clicked}">
            <div class="text__blue pointer justify__content__right align__content__center" @click="clicked" >
                <div>
                    Забыли пароль?
                </div>
            </div>
        </template>

        <div class="form__table grid grid__gap__10">
            <div class="form__input">
                <div class="input__header">
                    E-mail
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="email"
                    type-input="email"
                    :errors="validateErrors.email"
                />
            </div>
        </div>

        <template #form__buttons="{clicked}">
            <div class="double__columns__right">
                <ButtonGreen @wasClicked="send">Отправить</ButtonGreen>

                <ButtonNone @wasClicked="clicked">Отменить</ButtonNone>
            </div>
        </template>
    </ModalForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalForm from '../../Modals/ModalForm.vue'
import ButtonGreen from '../../UI/buttons/ButtonGreen.vue'
import ButtonNone from '../../UI/buttons/ButtonNone.vue'
import InputText from '../../UI/input/InputText.vue'
import RequiredInput from '../../UI/input/RequiredInput.vue'

export default {
    name: 'RecoveryPasswordForm',
    components: {
        RequiredInput,
        InputText,
        ButtonNone,
        ButtonGreen,
        ModalForm,
    },

    computed: {
        ...mapGetters({
            validateErrors: 'getValidateErrors'
        })
    },

    data () {
        return {
            isAwait: false,
            openedModal: false,
            email: null
        }
    },

    methods: {
        ...mapActions(['recoveryConfirmation']),

        open () {
            this.openedModal = !this.openedModal

            if (this.openedModal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        },

        send () {
            if (!this.isAwait) {
                this.isAwait = true;

                this.recoveryConfirmation({ email: this.email })
                    .then(() => {
                        this.isAwait = false
                        this.openedModal = false
                    })
                    .catch(() => {
                        this.isAwait = false
                    })
            }
        }
    }
}
</script>