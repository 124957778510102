<template>
    <component :is="layout" :use-page-layout="usePageLayout">
        <router-view/>
    </component>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { APP_ENV } from './scripts/static'

import MainLayout from './layouts/MainLayout.vue'
import HomeLayout from './layouts/HomeLayout.vue'
import DashboardLayout from './layouts/DashboardLayout.vue'
import DashboardLoginLayout from './layouts/DashboardLoginLayout.vue'

export default {
    components: {
        MainLayout,
        HomeLayout,
        DashboardLayout,
        DashboardLoginLayout
    },

    computed: {
        ...mapGetters({
            authUser: 'getAuthUser',
            item: 'getItem',
            deal: 'getDeal',
            ticket: 'getTicket'
        }),

        layout () {
            return (this.$route.meta.layout || 'main') + '-layout'
        },

        usePageLayout () {
            return (this.$route.meta.usePageLayout ?? true)
        }
    },

    data () {
        return {
            metaTitle: null,
            metaType: null,

            metaKeywords: '',
            metaDescription: '',
            metaDescriptionType: null
        }
    },

    watch: {
        $route (to) {
            if (to.meta) {
                this.metaTitle = to.meta.title
                this.metaType = to.meta.type
                this.metaDescriptionType = to.meta.descriptionType
            }

            this.updateTitle()
            this.updateDescription()

            this.loadErrorPageMutation(null)

            this.setLoaderFailed(null)

            document.body.style.overflow = 'auto'

            window.scrollTo({ top: 0 });
        },

        authUser () {
            if (this.authUser) {
                this.existsNotViewed()
            }
        },

        artist () {
            this.updateTitle()
            this.updateDescription()
        },

        deal () {
            this.updateTitle()
        },

        ticket () {
            this.updateTitle()
        },

        item () {
            this.updateTitle()
            this.updateDescription()
        },

        metaTitle () {
            document.title = this.metaTitle ? this.metaTitle + ' | Art2Mart' : 'Art2Mart'
        },

        metaDescription () {
            document.querySelector('meta[name="description"]').setAttribute("content", this.metaDescription)
        },

        metaKeywords () {
            document.querySelector('meta[name="keywords"]').setAttribute("content", this.metaKeywords)
        },

        metaDescriptionType () {
            this.updateDescription()
        }
    },

    methods: {
        ...mapActions(['existsNotViewed']),
        ...mapMutations(['loadErrorPageMutation', 'setLoaderFailed']),

        updateTitle () {
            switch (this.metaType) {
                case 'artist' :
                    this.metaTitle += this.artist ? ' ' + this.artist?.name : ''

                    break
                case 'item':
                    this.metaTitle += this.item ? ' ' + this.item?.name + ' художника ' + this.item?.artist?.name : ''

                    break
                case 'deal':
                    this.metaTitle += this.deal ? ' №' + this.deal?.id : ''

                    break
                case 'ticket':
                    this.metaTitle += this.ticket ? ' ' + this.ticket?.name : ''

                    break
            }
        },

        updateDescription () {
            switch (this.metaDescriptionType) {
                case 'home' :
                    this.metaDescription = 'Купить или продать обложку вы можете в интернет магазине Art2Mart. Широкий выбор оригинальных обложек для электронных книг. Высокое качество товаров и услуг. Сотрудничество с талантливыми художниками.'
                    this.metaKeywords = 'обложки для электронных книг, купить, продать, обложки на заказ, маркетплейс для художников, услуги художников'

                    break
                case 'catalog':
                    this.metaDescription = 'Купить обложку для книги в интернет магазине Art2Mart. Лучшие работы художников будут лицом ваших книг.'
                    this.metaKeywords = 'каталог обложек, магазин обложек для книг'

                    break
                case 'artists':
                    this.metaDescription = 'Список художников в интернет магазине Art2Mart.'
                    this.metaKeywords = ' художник обложек книг, художника, обложки, купить, покупка'

                    break
                case 'item':
                    this.metaDescription = 'Обложка для книги ' + this.item?.name + ' купить в интернет магазине Art2Mart от художника ' + this.item?.artist?.name + '. Выберите лучшее из доступных обложек в онлайн каталоге.'
                    this.metaKeywords = 'обложка для электронной книги'

                    break
                case 'artistStore':
                    this.metaDescription = 'Магазин художника ' + this.artist?.name + ' в интернет магазине Art2Mart. Оригинальные обложки для электронных книг.'
                    this.metaKeywords = 'художник, художника, магазин'

                    break
                case 'artistPortfolio':
                    this.metaDescription = 'Портфолио художника ' + this.artist?.name + ' в интернет магазине Art2Mart. Оригинальные обложки для электронных книг.'
                    this.metaKeywords = 'художник, художника, портфолио'

                    break
                case 'searchItems':
                    this.metaDescription = 'Поиск обложек для книг в интернет магазине Art2Mart. Лучшие работы художников будут лицом ваших книг.'
                    this.metaKeywords = 'поиск обложек'

                    break
                default:
                    this.metaDescription = 'Art2Mart интернет-магазин обложек для книг'
                    this.metaKeywords = 'art2mart'

                    break
            }
        }
    },

    mounted () {
        if (APP_ENV === 'production') {
            (function (m, e, t, r, i, k, a) {
                m[i] = m[i] || function () {
                    (m[i].a = m[i].a || []).push(arguments)
                };
                m[i].l = 1 * new Date();

                for (let j = 0; j < document.scripts.length; j++) {
                    if (document.scripts[j].src === r) {
                        return
                    }
                }

                k = e.createElement(t)
                a = e.getElementsByTagName(t)[0]
                k.async = 1
                k.src = r
                a.parentNode.insertBefore(k, a)
            })
            (window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

            ym(92775624, 'init', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                ecommerce: 'dataLayer'
            });
        }
    }
}
</script>

<style>
@import './assets/css/art2mart.css';
@import './assets/css/breadcrumbs.css';
@import './assets/css/alert.css';
@import './assets/css/buttons.css';
@import './assets/css/dashboard.css';
@import "assets/css/styles.css";
@import "assets/css/select.css";
@import "assets/css/chat.css";
@import "assets/css/form.css";
@import "assets/css/navigate.css";
@import "assets/css/notifications.css";
@import "assets/css/profile.css";
@import "assets/css/items.css";
@import "assets/css/modal.css";
@import "assets/css/deals.css";
@import "assets/css/ticket.css";
@import "assets/css/media.css";

@import '../node_modules/@egjs/flicking-plugins/dist/pagination.css';
@import '../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css';

.flicking-pagination-bullet {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: rgb(10 10 10 / 10%);
    cursor: pointer;
    font-size: 1rem;
}

.flicking-pagination-bullet-active {
    background-color: var(--app-color-primary);
}
</style>
