<template>
    <ul v-for="error in errors" :key="error" class="validate__errors">
        <li class="text__error">{{ error }}</li>
    </ul>
</template>

<script>
export default {
    name: 'ValidateErrorsForm',

    props: {
        errors: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.validate__errors {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
    .validate__errors .text__error {
        color: var(--app-color-danger);
        font-size: 14px;
        margin-left: 5px;
    }
</style>
