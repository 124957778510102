<template>
    <Icon icon="search"/>
</template>

<script>
import Icon from './Icon'

export default {
    name: 'IconSearch',

    components: {
        Icon
    }
}
</script>

<style scoped>

</style>