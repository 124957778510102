export default {
    state: {
        messages: [],
    },
    getters: {
        flashMessages (state) {
            return state.messages
        }
    },
    mutations: {
        setSuccessMessage: (state, message) => state.messages.push({ message: message, type: 'success' }),
        setErrorMessage: (state, message) => state.messages.push({ message: message, type: 'error' }),

        removeFlashMessage (state, index) {
            state.messages.splice(index, 1)
        },

        errorMessagesMutation (state, message) {
            state.messages.push({ message: message, type: 'error' })
        },

        warningMessagesMutation (state, message) {
            state.messages.push({ message: message, type: 'warning' })
        }
    },
}
