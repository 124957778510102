import { get, post } from '../../scripts/ajax'
import { mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        transactions: []
    },

    mutations: {
        setTransactions: mutationWithPaginate('transactions'),

        clearTransactions: state => state.transactions = []
    },

    getters: {
        getTransactions: state => state.transactions
    },

    actions: {
        async loadTransactions (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderTransactions', true)
            }

            return new Promise((resolve, reject) => {
                get('/transactions', data.params)
                    .then(response => {
                        ctx.commit('setTransactions', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorTransactions', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderTransactions', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async depositBalance (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/transactions/deposit', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setAuthUser', response.data.user)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async withdrawBalance (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/transactions/withdraw', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setAuthUser', response.data.user)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)
                        ctx.commit('setValidateErrors', error.response.data.errors)

                        reject(error)
                    })
            })
        }
    }
}
