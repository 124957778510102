<template>
    <router-link
        :to="link"
        :class="colorButton"
        class="button"
    >
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'Button',

    props: {
        colorButton: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>