import { post } from '../../scripts/ajax'

export default {
    state: {},

    mutations: {},

    getters: {},

    actions: {
        async recoveryConfirmation (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/confirmations/recovery', { email: data.email })
                    .then(response => {
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
