import { del, get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        notifications: [],
        notificationsMessages: [],

        existsNotViewed: false,
        existsNotViewedMessages: false
    },

    mutations: {
        setNotifications: mutationWithPaginate('notifications'),
        setNotificationsMessages: mutationWithPaginate('notificationsMessages'),

        setExistsNotViewed: mutation('existsNotViewed'),
        setExistsNotViewedMessages: mutation('existsNotViewedMessages'),

        clearNotifications: state => state.notifications = [],
        clearNotificationsMessages: state => state.notificationsMessages = [],
    },

    getters: {
        getNotifications: state => state.notifications,
        getNotificationsMessages: state => state.notificationsMessages,

        getExistsNotViewed: state => state.existsNotViewed,
        getExistsNotViewedMessages: state => state.existsNotViewedMessages
    },

    actions: {
        async loadNotificationsSystem (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/notifications/system', data.params)
                    .then(response => {
                        ctx.commit('setNotifications', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorNotifications', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error)

                        reject(error)
                    })
            })
        },

        async loadNotificationsMessages (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/notifications/messages', data.params)
                    .then(response => {
                        ctx.commit('setNotificationsMessages', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorNotifications', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },
        // Проверяет наличие непросмотренных уведомлений
        async existsNotViewed (ctx) {
            return new Promise((resolve, reject) => {
                get('/notifications/not_viewed')
                    .then(response => {
                        ctx.commit('setExistsNotViewed', response.data.system)
                        ctx.commit('setExistsNotViewedMessages', response.data.messages)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },
        // Отмечает уведомление как прочитанное
        async viewNotificationSystem (ctx, id) {
            return new Promise((resolve, reject) => {
                post('/notifications/system/view/' + id)
                    .then(response => {

                        ctx.commit('setNotifications', {
                            items: response.data.paginator.data,
                            paginate: false
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async viewNotificationMessages (ctx, id) {
            return new Promise((resolve, reject) => {
                post('/notifications/messages/view/' + id)
                    .then(response => {

                        ctx.commit('setNotificationsMessages', {
                            items: response.data.paginator.data,
                            paginate: false
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async deleteNotificationSystem (ctx, id) {
            return new Promise((resolve, reject) => {
                del('/notifications/system/' + id)
                    .then(response => {

                        ctx.commit('setNotifications', {
                            items: response.data.paginator.data,
                            paginate: false
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async deleteNotificationMessages (ctx, id) {
            return new Promise((resolve, reject) => {
                del('/notifications/messages/' + id)
                    .then(response => {

                        ctx.commit('setNotificationsMessages', {
                            items: response.data.paginator.data,
                            paginate: false
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },
    }
}
