<template>
    <template></template>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'CheckModerator',

    computed: {
        ...mapGetters({
            authModerator: 'getAuthModerator'
        })
    },

    watch: {
        authModerator () {
            this.check()
        },

        $route () {
            this.check()
        }
    },

    methods: {
        ...mapMutations(['loadErrorPageMutation']),

        check () {
            if (!this.authModerator) {
                this.loadErrorPageMutation(401)
            } else {
                this.loadErrorPageMutation(null)
            }
        }
    },

    mounted () {
        this.check()
    }
}
</script>