import { get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        isFollow: false,

        followers: [],
        follows: [],
    },

    mutations: {
        setIsFollow: mutation('isFollow'),

        setFollowers: mutationWithPaginate('followers'),
        setFollows: mutationWithPaginate('follows'),

        clearFollowers: state => state.followers = [],
        clearFollows: state => state.follows = [],
    },

    getters: {
        getIsFollow: state => state.isFollow,

        getFollowers: state => state.followers,
        getFollows: state => state.follows,
    },

    actions: {
        async checkFollow (ctx, artistId) {
            return new Promise((resolve, reject) => {
                get('/followers/' + artistId + '/check')
                    .then(response => {
                        ctx.commit('setIsFollow', response.data.is_follow)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadFollowers (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/followers/' + data.artistId, data.params)
                    .then(response => {
                        ctx.commit('setFollowers', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorFollowers', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadFollows (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/follows/', data.params)
                    .then(response => {
                        ctx.commit('setFollows', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorFollows', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async follow (ctx, artistId) {
            return new Promise((resolve, reject) => {
                post('/followers/' + artistId + '/follow')
                    .then(response => {
                        ctx.commit('setIsFollow', response.data.is_follow)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async unfollow (ctx, artistId) {
            return new Promise((resolve, reject) => {
                post('/followers/' + artistId + '/unfollow')
                    .then(response => {
                        ctx.commit('setIsFollow', response.data.is_follow)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
