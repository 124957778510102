import { get } from '../../scripts/ajax'
import { mutationWithPaginate } from '../../scripts/scripts'
import cookie from 'vue-cookies'
import axios from 'axios'
import { SERVER_API } from '../../scripts/static'

export default {
    state: {
        agentReports: []
    },

    mutations: {
        setAgentReports: mutationWithPaginate('agentReports'),

        clearAgentReports: state => state.agentReports = []
    },

    getters: {
        getAgentReports: state => state.agentReports
    },

    actions: {
        async downloadAgentReport (ctx, id) {
            return new Promise((resolve, reject) => {
                const authorization = cookie.get('authToken') ? { Authorization: 'Bearer ' + cookie.get('authToken') } : {}

                const headers = Object.assign(authorization)

                axios.get(SERVER_API + '/agent/report/download/' + id, {
                    headers, responseType: 'blob'
                })
                    .then(response => {
                        let link = document.createElement('a')

                        link.href = URL.createObjectURL(response.data)
                        link.setAttribute('download', 'agent_report_' + id + '.pdf')

                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        async loadAgentReports (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/agent/report', data.params)
                    .then(response => {
                        ctx.commit('setAgentReports', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorAgentReports', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
