<template>
    <div v-if="!applyPolicies" class="container __cookie shadow__box font-size__15">
        <div class="container__body">
            <div>
                <p>
                    Этот сайт использует файлы cookie, которые обеспечивают его корректную работу.
                </p>

                <p>
                    Используя сайт,
                    вы предоставляете согласие на обработку файлов cookie в соответствии с
                    <router-link to="/confidential">Политикой обработки персональных данных</router-link>
                    .
                </p>
            </div>

            <div class="justify__content__right">
                <ButtonBlue @click="apply">
                    Хорошо
                </ButtonBlue>
            </div>
        </div>

        <div class="container__footer"></div>
    </div>
</template>

<script>
import cookie from 'vue-cookies'

import ButtonBlue from '../UI/buttons/ButtonBlue.vue'

export default {
    name: 'Cookie',

    components: {
        ButtonBlue
    },

    data () {
        return {
            applyPolicies: this.checkApply()
        }
    },

    methods: {
        apply () {
            cookie.set('applyPolicies', true, '12M')

            this.applyPolicies = true
        },

        checkApply () {
            return cookie.get('applyPolicies')
        }
    }
}
</script>