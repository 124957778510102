<template>
    <div class="dropdown">

        <span class="user__logo pointer" :class="visibleContent ? 'active' : ''" @click="toggle">
            <ThumbImage
                :image="authUser.image"
            />
        </span>

        <transition name="fade">
            <div v-if="visibleContent" class="dropdown__content__user__menu grid__gap__15 shadow__box align__center">

                <div class="balance grid text__dark">
                    <span class="bold font-size__18">{{ formatMoney(authUser.balance / 100) }}</span>
                    <span class="text__grey font-size__14">Баланс</span>
                </div>

                <router-link to="/profile/deals" class="text__grey hover__link__primary">
                    Сделки
                </router-link>

                <router-link to="/profile/finance" class="text__grey hover__link__primary">
                    Финансы
                </router-link>

                <router-link to="/profile/follows" class="text__grey hover__link__primary">
                    Подписки
                </router-link>

                <router-link to="/profile/settings" class="text__grey hover__link__primary">
                    Настройки
                </router-link>

                <router-link v-if="authUser?.artist" to="/profile/documents/reports" class="text__grey hover__link__primary">
                    Документы
                </router-link>

                <router-link :to="authUser?.artist?.id ? '/artist/'+authUser?.artist?.id+'/store' : '/profile/artist'" class="text__grey hover__link__primary">
                    Страница художника
                </router-link>

                <router-link to="/profile/personal" class="text__grey hover__link__primary">
                    Персональные данные
                </router-link>

                <ButtonNone @click="logout">Выйти из профиля</ButtonNone>

            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatMoney } from '../../../scripts/scripts'

import ThumbImage from '../../Images/ThumbImage.vue'
import ButtonNone from '../buttons/ButtonNone.vue'

export default {
    name: 'DropdownUserMenu',

    components: {
        ButtonNone,
        ThumbImage
    },

    props: {
        modelValue: {
            type: Boolean
        }
    },

    computed: {
        ...mapGetters({
            authUser: 'getAuthUser',
            artist: 'getArtist'
        })
    },

    data () {
        return {
            visibleContent: false
        }
    },

    watch: {
        $route () {
            this.visibleContent = false
        }
    },

    methods: {
        ...mapActions(['logout']),

        formatMoney,

        toggle () {
            this.visibleContent = !this.visibleContent
        }
    },

    mounted () {
        window.addEventListener('click', (event) => {
            let parent = (node) => {
                if (node?.classList?.contains('dropdown') || node?.classList?.contains('select__options')) {
                    return
                }

                if (node?.parentNode) {
                    parent(node?.parentNode)
                } else {
                    this.visibleContent = false
                }
            }

            if (this.visibleContent) {
                parent(event?.target)
            }
        })
    }
}
</script>

<style scoped>
.dropdown__content__user__menu .balance {
    width: 90%;
}
</style>