<template>
    <Icon icon="bell"/>
</template>

<script>
import Icon from './Icon'

export default {
    name: 'IconBell',

    components: {
        Icon
    }
}
</script>

<style scoped>

</style>