<template>
    <div class="error__page__content">
        <div>
            <div class="h4 align__center">
                Ошибка {{ error }}
            </div>
            <div class="h4 align__center">

                <span v-if="error === 401">
                    Для получения доступа необходимо авторизоваться
                </span>

                <span v-if="error === 403">
                    Недостаточно прав для просмотра страницы
                </span>

                <span v-if="error === 404">
                    Страница не найдена
                </span>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShowErrorPage',

    props: {
        error: {
            required: true,
            type: Number
        }
    }
}
</script>

<style scoped>

</style>