import { get, post } from '../../scripts/ajax'
import { deleteCookie, mutation, setCookie } from '../../scripts/scripts'

import router from '@/router'

export default {
    state: {
        authUser: null,

        authModerator: null
    },

    mutations: {
        setAuthUser: mutation('authUser'),

        setAuthModerator: mutation('authModerator')
    },

    getters: {
        getAuthUser: state => state.authUser,

        getAuthModerator: state => state.authModerator
    },

    actions: {
        async register (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/register', data)
                    .then(response => {
                        setCookie('authToken', response.data.token)

                        ctx.commit('setAuthUser', response.data.user)
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async login (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/login', data)
                    .then(response => {
                        setCookie('authToken', response.data.token)

                        ctx.commit('setAuthUser', response.data.user)
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loginModerator (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/dashboard/login', data, {}, true)
                    .then(response => {
                        setCookie('authTokenModerator', response.data.token)

                        ctx.commit('setAuthModerator', response.data.moderator)
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        router.push('/dashboard')

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async authorise (ctx) {
            ctx.commit('setLoaderAuthUser', true)

            return new Promise((resolve, reject) => {
                get('/auth')
                    .then(response => {
                        const authUser = response.data.user

                        ctx.commit('setAuthUser', authUser ? authUser : null)
                        ctx.commit('setLoaderAuthUser', false)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async authoriseModerator (ctx) {
            ctx.commit('setLoaderAuthUser', true)

            return new Promise((resolve, reject) => {
                get('/dashboard/auth', {}, {}, true)
                    .then(response => {
                        ctx.commit('setAuthModerator', response.data.moderator)
                        ctx.commit('setLoaderAuthUser', false)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async logout (ctx) {
            get('/logout')
                .then(() => {
                    deleteCookie('authToken')

                    ctx.commit('setAuthUser', null)

                    router.push('/')
                })
        },

        async logoutModerator (ctx) {
            get('/dashboard/logout', {}, {}, true)
                .then(() => {
                    deleteCookie('authTokenModerator')

                    ctx.commit('setAuthModerator', null)

                    router.push('/dashboard/login')
                })
        }
    }
}
