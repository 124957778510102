<template>
    <CheckModerator/>

    <div v-if="loadErrorPage">
        <ShowErrorPage :error="loadErrorPage"/>

        <div class="grid__content__center">
            <div>
                <ButtonBlueLink link="/dashboard/login">
                    Авторизоваться
                </ButtonBlueLink>
            </div>
        </div>
    </div>
    <div v-else>
        <FlashMessage/>

        <div class="dashboard__page">
            <div>
                <div class="dashboard__logo">
                    <router-link to="/dashboard">
                        <img class="logo" src="/images/logo.png" alt="logo">
                    </router-link>
                </div>

                <DashboardNavigate/>
            </div>

            <div class="grid__gap__30">

                <DashboardHeader/>

                <div class="dashboard__content grid__gap__30">
                    <Breadcrumbs/>

                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FlashMessage from '../components/modals/FlashMessage.vue'
import DashboardNavigate from '../components/Navigate/DashboardNavigate.vue';
import DashboardHeader from '../components/Main/DashboardHeader.vue';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs.vue';
import CheckModerator from '../components/Security/CheckModerator.vue';
import ShowErrorPage from '../components/ShowErrorPage.vue';
import ButtonBlueLink from '../components/UI/buttons/ButtonBlueLink.vue';

export default {
    name: 'DashboardLayout',

    components: {
        ButtonBlueLink,
        ShowErrorPage,
        CheckModerator,
        Breadcrumbs,
        DashboardHeader,
        DashboardNavigate,
        FlashMessage
    },

    computed: {
        ...mapGetters({
            'loadErrorPage': 'loadErrorPageGetter'
        })
    },

    methods: {
        ...mapActions(['authoriseModerator'])
    },

    mounted () {
        this.authoriseModerator()
    }
}
</script>
