<template>

    <li class="breadcrumb__item">-</li>

    <li v-if="item.url" class="breadcrumb__item">
        <router-link :to="item.url">{{ item.name }}</router-link>
    </li>

    <li v-else class="breadcrumb__item">
        {{ item.name }}
    </li>

</template>

<script>
export default {
    name: 'BreadcrumbItem',

    props: {
        item: {
            required: true,
            type: Object
        }
    }
}
</script>