<template>
    <div class="flash__container">
        <div
            v-for="(flash, index) in flashMessages"
            :key="flash"
            :class="flash.type"
            class="flash__message"
            @click="deleteFlashMessage(index)"
            :attr="deleteFlashMessageTimeout(index, flash.timeout)"
        >
            <div class="icon">
                <i :class="'icon__' + flash.type "></i>
            </div>

            <div class="message">
                {{ flash.message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlashMessage',
    computed: {
        flashMessages () {
            return this.$store.getters.flashMessages
        }
    },
    methods: {
        deleteFlashMessage (index) {
            this.$store.commit('removeFlashMessage', index)
        },
        deleteFlashMessageTimeout (index, timeout) {
            setTimeout(() => this.deleteFlashMessage('removeFlashMessage', index), (timeout ?? 5) * 1000 * (index + 1))
        }
    }
}
</script>

<style scoped>

</style>
