<template>
    <div class="footer">

        <div class=" font-size__15">
            <div class="footer__content grid__gap__30">
                <div class="font-size__16 align__content__center copy__area" style="grid-area: copy">
                    &copy; {{ new Date().getFullYear() }} Art2Mart
                </div>

                <div class="align__content__center" style="grid-area: attention">
                    <span class="">
                        <span class="attention">
                            18+
                        </span>
                        Сайт может содержать материалы, не предназначенные для просмотра лицами, не достигшими 18 лет!
                    </span>
                </div>

                <div class="justify__content__right social__area" style="grid-area: empty_line_1">

                </div>

                <div class="font-size__16 align__content__center copy__area" style="grid-area: email">
                    E-mail:
                    <br>
                    <a href="mailto:admin@art2mart.ru" class="hover__link__white">
                        admin@art2mart.ru
                    </a>
                </div>

                <div class="align__content__center" style="grid-area: offer">
                    <router-link to="/library" class="text__white hover__link__white">
                        Библиотека знаний
                    </router-link>

                    <router-link to="/offer" class="text__white hover__link__white">
                        Публичная оферта
                    </router-link>

                    <router-link to="/agent-offer" class="text__white hover__link__white">
                        Оферта агентского договора
                    </router-link>
                </div>

                <div class="justify__content__right social__area" style="grid-area: empty_line_2">

                </div>

                <div class="font-size__16 align__content__center copy__area" style="grid-area: support">
                    Поддержка:
                    <br>
                    <a href="mailto:support@art2mart.ru" class="hover__link__white">
                        support@art2mart.ru
                    </a>
                </div>

                <div class="align__content__center" style="grid-area: rules">
                    <router-link to="/rules" class="text__white hover__link__white">
                        Правила пользования
                    </router-link>

                    <router-link to="/confidential" class="text__white hover__link__white">
                        Политика обработки персональных данных
                    </router-link>

                    <router-link to="/consent-confidential" class="text__white hover__link__white">
                        Согласие на обработку персональных данных
                    </router-link>
                </div>

                <div class="justify__content__right social__area" style="grid-area: social">
                    <a href="https://vk.com/art2mart" target="_blank" class="font-size__40 text__white hover__link__white">
                        <IconVk/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconVk from '../UI/icons/IconVk.vue'

export default {
    name: 'Footer',

    components: {
        IconVk
    }
}
</script>

<style>
.footer {
    display: grid;
    background: var(--app-bg-dark);
    color: var(--app-color-white);
}
.footer a {
    color: #c1dff7;
}
.footer a.active {
    color: var(--app-color-white);
}
.footer__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "copy attention empty_line_1"
        "email offer empty_line_2"
        "support rules social";
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: 1320px;
    width: 100%;
    box-sizing: border-box;
    margin: 30px auto;
}
</style>