import { del, get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'

const STORE_URL = '/items/store'
const PORTFOLIO_URL = '/items/portfolio'

export default {
    state: {
        item: null,

        items: [],
        similarItems: [],
        otherUserItems: [],
        searchItems: [],
        portfolioItems: [],
        newItems: [],
        discountItems: []
    },

    mutations: {
        setItem: mutation('item'),

        setItems: mutationWithPaginate('items'),
        setSearchItems: mutationWithPaginate('searchItems'),
        setPortfolioItems: mutationWithPaginate('portfolioItems'),
        setSimilarItems: mutation('similarItems'),
        setOtherUserItems: mutation('otherUserItems'),
        setNewItems: mutation('newItems'),
        setDiscountItems: mutation('discountItems'),

        clearItem: state => state.item = null,

        clearItems: state => state.items = [],
        clearSearchItems: state => state.searchItems = [],
        clearPortfolioItems: state => state.portfolioItems = [],
        clearOtherUserItems: state => state.otherUserItems = [],
        clearSimilarItems: state => state.similarItems = [],
        clearNewItems: state => state.newItems = [],
        clearDiscountItems: state => state.discountItems = []
    },

    getters: {
        getItem: state => state.item,

        getItems: state => state.items,
        getOtherUserItems: state => state.otherUserItems,
        getSimilarItems: state => state.similarItems,
        getSearchItems: state => state.searchItems,
        getPortfolioItems: state => state.portfolioItems,
        getNewItems: state => state.newItems,
        getDiscountItems: state => state.discountItems
    },

    actions: {
        async loadNewItems (ctx) {
            ctx.commit('setLoaderNewItems', true)

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/new')
                    .then(response => {
                        ctx.commit('setNewItems', response.data.items)
                        ctx.commit('setLoaderNewItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadDiscountItems (ctx) {
            ctx.commit('setLoaderDiscountItems', true)

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/discount')
                    .then(response => {
                        ctx.commit('setDiscountItems', response.data.items)
                        ctx.commit('setLoaderDiscountItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadItems (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderItems', true)
            }

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/artist/' + data.artistId, data?.params)
                    .then(response => {
                        ctx.commit('setItems', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorItems', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadOtherItems (ctx, data) {
            ctx.commit('setLoaderOtherUserItems', true)

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/artist/' + data.artistId + '/' + data.itemId)
                    .then(response => {
                        ctx.commit('setOtherUserItems', response.data.items)
                        ctx.commit('setLoaderOtherUserItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadSimilarItems (ctx, itemId) {
            ctx.commit('setLoaderOtherUserItems', true)

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/similar/' + itemId)
                    .then(response => {
                        ctx.commit('setSimilarItems', response.data.items)
                        ctx.commit('setLoaderOtherUserItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadPortfolioItems (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderPortfolioItems', true)
            }

            return new Promise((resolve, reject) => {
                get(PORTFOLIO_URL + '/artist/' + data.artistId, data?.params)
                    .then(response => {
                        ctx.commit('setPortfolioItems', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorPortfolioItems', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderPortfolioItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadItem (ctx, itemId) {
            ctx.commit('setLoaderItem', true)

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/' + itemId)
                    .then(response => {
                        const item = response.data.item

                        if (item || item.length) {
                            ctx.commit('setItem', item)
                        } else {
                            ctx.commit('loadErrorPageMutation', 404)
                        }

                        ctx.commit('setLoaderItem', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async searchItems (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderSearchItems', true)
            }

            return new Promise((resolve, reject) => {
                get(STORE_URL + '/search', data.params)
                    .then(response => {
                        ctx.commit('setSearchItems', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorSearchItems', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderSearchItems', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async createItem (ctx, data) {
            return new Promise((resolve, reject) => {
                post(STORE_URL, data, { 'Content-Type': 'multipart/form-data' })
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message ? error.response.data.message : error.response.data)

                        reject(error)
                    })
            })
        },

        async updateItem (ctx, data) {
            return new Promise((resolve, reject) => {
                post(STORE_URL + '/' + data.get('id'), data, { 'Content-Type': 'multipart/form-data' })
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setItem', response.data.item)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async createPortfolioItem (ctx, data) {
            return new Promise((resolve, reject) => {
                post(PORTFOLIO_URL, data, { 'Content-Type': 'multipart/form-data' })
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async updatePortfolioItem (ctx, data) {
            return new Promise((resolve, reject) => {
                post(PORTFOLIO_URL + '/' + data.get('id'), data, { 'Content-Type': 'multipart/form-data' })
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setItem', response.data.item)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async saleItem (ctx, itemId) {
            return new Promise((resolve, reject) => {
                post(STORE_URL + '/sale/' + itemId)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setItem', response.data.item)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async discountItem (ctx, data) {
            return new Promise((resolve, reject) => {
                post(STORE_URL + '/discount/' + data.id, data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setItem', response.data.item)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async deleteItem (ctx, itemId) {
            return new Promise((resolve, reject) => {
                del(STORE_URL + '/' + itemId)
                    .then(response => {
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async deletePortfolioItem (ctx, itemId) {
            return new Promise((resolve, reject) => {
                del(PORTFOLIO_URL + '/' + itemId)
                    .then(response => {
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
