import { get, post } from '../../scripts/ajax'
import { pop } from '../../scripts/scripts'

export default {
    state: {
        messages: []
    },

    mutations: {
        setMessages: (state, data) => {
            if (data.paginate) {
                state.messages.unshift(...data.items)
            } else {
                state.messages = data.items
            }
        },

        setMessage: (state, message) => {
            let lastMessagesDate = pop(state.messages)

            if (lastMessagesDate && lastMessagesDate.date === message.date) {
                let userMessages = pop(lastMessagesDate.users)

                if (userMessages.artist && pop(message.users).artist && userMessages.artist.id === pop(message.users).artist.id || userMessages.user && pop(message.users).user && userMessages.user.id === pop(message.users).user.id) {
                    userMessages.messages.push(pop(pop(message.users).messages))
                } else {
                    lastMessagesDate.users.push(pop(message.users))
                }
            } else {
                state.messages.push(message)
            }
        },

        clearMessages: state => state.messages = []
    },

    getters: {
        getMessages: state => state.messages
    },

    actions: {
        async createDealMessage (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/messages/' + data.deal_id, data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setMessage', pop(response.data.message))

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadDealMessages (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderMessages', true)
            }

            return new Promise((resolve, reject) => {
                get('/messages/deal/' + data.dealId, data.params)
                    .then(response => {
                        ctx.commit('setMessages', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorMessages', response.data.paginator.next_cursor)
                        ctx.commit('setLoaderMessages', false)

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
