<template>
    <ModalForm action-button-text="" form-header="Регистрация">
        <template #button="{clicked}">
            <span class="pointer" @click="clicked">Регистрация</span>
        </template>

        <div class="grid__gap__30">
            <div class="form__input">
                <div class="input__header">
                    Имя
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="name"
                    type-input="text"
                    :min-length="3"
                    :max-length="30"
                    :show-count-chars=true
                    :errors="validateErrors.name"
                />

            </div>

            <div class="form__input">
                <div class="input__header">
                    E-mail
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="email"
                    type-input="email"
                    :errors="validateErrors.email"
                />
            </div>

            <div class="form__input">
                <div class="input__header">
                    Пароль
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="password"
                    type-input="password"
                    :errors="validateErrors.password"
                />
            </div>

            <div class="form__input">
                <div class="input__header">
                    Повторите пароль
                    <RequiredInput/>
                </div>

                <InputText
                    v-model="password_confirm"
                    type-input="password"
                    :errors="validateErrors.password_confirm"
                />
            </div>

            <div v-if="validateErrors.agree?.length">
                <ValidateErrorsForm :errors="validateErrors.agree"/>
            </div>

            <div class="double__columns__checkbox">
                <div class="align__content__center">
                    <InputCheckbox
                        v-model="agree"
                        :checked="agree"
                        name="agree"
                    />
                </div>

                <div class="text__dark">
                    Я ознакомилась(лся) и принимаю
                    <a href="/offer" target="_blank">условия публичной оферты</a>
                    и даю согласие на
                    <a href="/confidential" target="_blank">обработку персональную данных</a>
                </div>
            </div>
        </div>

        <template #form__buttons="{}">
            <div class="double__columns__right">
                <ButtonGreen @wasClicked="send">Регистрация</ButtonGreen>
            </div>
        </template>
    </ModalForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalForm from '../../Modals/ModalForm.vue'
import ButtonGreen from '../../UI/buttons/ButtonGreen.vue'
import InputText from '../../UI/input/InputText.vue'
import InputCheckbox from '../../UI/input/InputCheckbox.vue'
import ValidateErrorsForm from '../../forms/ValidateErrorsForm.vue'
import RequiredInput from '../../UI/input/RequiredInput.vue'

export default {
    name: 'RegisterForm',

    components: {
        RequiredInput,
        ValidateErrorsForm,
        InputCheckbox,
        InputText,
        ButtonGreen,
        ModalForm,
    },

    computed: {
        ...mapGetters({
            validateErrors: 'getValidateErrors'
        })
    },

    data () {
        return {
            isAwait: false,

            name: null,
            email: null,
            password: null,
            password_confirm: null,
            agree: false
        }
    },

    methods: {
        ...mapActions(['register']),

        send () {
            if (!this.isAwait) {
                this.isAwait = true;

                this.register({
                    agree: this.agree,
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    password_confirm: this.password_confirm
                })
                    .then(() => {
                        document.body.style.overflow = 'auto'
                    })
                    .catch(() => {
                        this.isAwait = false
                    })
            }
        }
    }
}
</script>