import { get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'
import cookie from "vue-cookies";
import axios from "axios";
import {SERVER_API} from "../../scripts/static";

export default {
    state: {
        artist: null,

        artistDeleteBid: null,

        artists: []
    },

    mutations: {
        setArtist: mutation('artist'),
        setArtists: mutationWithPaginate('artists'),

        setArtistDeleteBid: mutation('artistDeleteBid'),

        clearArtist: state => state.artist = null,
        clearArtists: state => state.artists = [],

        clearArtistDeleteBid: state => state.artistDeleteBid = null,
    },

    getters: {
        getArtist: state => state.artist,
        getArtists: state => state.artists,

        getArtistDeleteBid: state => state.artistDeleteBid,
    },

    actions: {
        async loadArtist (ctx, artistId) {
            return new Promise((resolve, reject) => {
                get('/artist/' + artistId)
                    .then(response => {
                        ctx.commit('setArtist', response.data.artist)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadArtistFromAuthUser (ctx) {
            return new Promise((resolve, reject) => {
                get('/artist')
                    .then(response => {
                        ctx.commit('setArtist', response.data.artist)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadArtistDeleteBid (ctx) {
            return new Promise((resolve, reject) => {
                get('/artist/delete/bid')
                    .then(response => {
                        ctx.commit('setArtistDeleteBid', response.data.artist_delete_bid)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async searchArtists (ctx, data) {
            return new Promise((resolve, reject) => {
                get('/artist/search', data?.params)
                    .then(response => {
                        ctx.commit('setArtists', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })
                        ctx.commit('setCursorArtists', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async downloadAgentOffer () {
            return new Promise((resolve, reject) => {
                const authorization = cookie.get('authToken') ? { Authorization: 'Bearer ' + cookie.get('authToken') } : {}

                const headers = Object.assign(authorization)

                axios.get(SERVER_API + '/artist/offer/download/', {
                    headers, responseType: 'blob'
                })
                    .then(response => {
                        let link = document.createElement('a')

                        link.href = URL.createObjectURL(response.data)
                        link.setAttribute('download', 'agent_offer.pdf')

                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)

                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        async createOrUpdateArtist (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/artist', data)
                    .then(response => {
                        ctx.commit('setArtist', response.data.artist)

                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setValidateErrors', [])

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)
                        ctx.commit('setValidateErrors', error.response.data.errors)

                        reject(error)
                    })
            })
        },

        async deleteArtist (ctx) {
            return new Promise((resolve, reject) => {
                post('/artist/delete/bid')
                    .then(response => {
                        ctx.commit('setArtistDeleteBid', response.data.artist_delete_bid)

                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async cancelDeleteArtist (ctx) {
            return new Promise((resolve, reject) => {
                post('/artist/delete/bid/cancel')
                    .then(response => {
                        ctx.commit('setArtistDeleteBid', response.data.artist_delete_bid)

                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
