import { DOMAIN } from './static'

export function setCookie (name, value) {
    document.cookie = name + '=' + value + ';path=/;domain=' + DOMAIN + ';expires=' + new Date(new Date().setMonth(new Date().getMonth() + 1)).toUTCString();
}
export function deleteCookie (name) {
    document.cookie = name + '=;path=/;domain=' + DOMAIN + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

export function checkUserRole (user, role) {
    return user?.roles?.find((userRole) => userRole.name === role)?.name.length > 0
}

export function pop (array) {
    return array[array.length - 1]
}

export function scrollToBottomOnUpdate () {
    document.getElementById('scroll').scrollTop = document.getElementById('scroll').scrollHeight
}

export function formatMoney (amount) {
    return new Intl.NumberFormat('ru-RU').format(amount)
}

export function parseQueryParams (params) {
    const requestParams = new URLSearchParams()
    for (name in params) {
        requestParams.set(name, params[name])
    }
    return requestParams
}

export const mutation = key => (state, val) => {
    state[key] = val
}

export const mutationWithPaginate = key => (state, data) => {
    if (data.paginate) {
        state[key].push(...data.items)
    } else {
        state[key] = data.items
    }
}