<template>
    <Button color-button="blue">
        <slot></slot>
    </Button>
</template>

<script>
import Button from './Button'

export default {
    name: 'ButtonBlue',

    components: {
        Button
    }
}
</script>

<style scoped>

</style>