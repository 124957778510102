<template>
    <div v-if="isLoading() && !loaderFailed" class="preloader">
        <div v-if="!loaderFailed" class="preloader__content">
            <div class="loader-align">
                <div class="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div v-else>
            <div v-if="!loaderFailed " class="preloader__content">
                {{ loaderFailed }}
            </div>
        </div>
    </div>

    <div>
        <div v-show="!loadErrorPage && !isLoading()" class="app__container">
            <slot/>
        </div>
    </div>

    <ShowErrorPage v-if="loadErrorPage" :error="loadErrorPage"/>
</template>

<script>
import { mapGetters } from 'vuex'

import ShowErrorPage from './ShowErrorPage.vue'

export default {
    name: 'Content',
    components: { ShowErrorPage },

    computed: {
        ...mapGetters({
            loaderAuthUser: 'getLoaderAuthUser',

            loaderItem: 'getLoaderItem',

            loaderItems: 'getLoaderItems',
            loaderNewItems: 'getLoaderNewItems',
            loaderSearchItems: 'getLoaderSearchItems',
            loaderPortfolioItems: 'getLoaderPortfolioItems',
            loaderOtherUserItems: 'getLoaderOtherUserItems',
            loaderDiscountItems: 'getLoaderDiscountItems',

            loaderMessages: 'getLoaderMessages',

            loaderNotifications: 'getLoaderNotifications',

            loaderDeal: 'getLoaderDeal',
            loaderCustomerDeals: 'getLoaderCustomerDeals',
            loaderArtistDeals: 'getLoaderArtistDeals',

            loaderTransactions: 'getLoaderTransactions',

            loaderUser: 'getLoaderUser',
            loaderUsers: 'getLoaderUsers',

            loaderTicket: 'getLoaderTicket',
            loaderMessagesTicket: 'getLoaderMessagesTicket',
            loaderTickets: 'getLoaderTickets',

            loaderFailed: 'getLoaderFailed'
        }),

        loadErrorPage () {
            return this.$store.getters.loadErrorPageGetter
        }
    },

    methods: {
        isLoading () {
            return this.loaderItems
                || this.loaderNewItems
                || this.loaderSearchItems
                || this.loaderPortfolioItems
                || this.loaderOtherUserItems
                || this.loaderDiscountItems

                || this.loaderItem

                || this.loaderMessages

                || this.loaderNotifications

                || this.loaderDeal
                || this.loaderCustomerDeals
                || this.loaderArtistDeals

                || this.loaderTransactions

                || this.loaderUser
                || this.loaderUsers

                || this.loaderAuthUser
        }
    }
}
</script>

<style scoped>
.preloader {
    width: 100%;
    min-height: calc(100vh - 170px);
    background: var(--app-color-white);

    z-index: 999;
}

.preloader__content {
    display: grid;
    justify-content: center;
    align-content: center;
}


.loader-align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.loader {
    width: 50px;
    height: 50px;
    /*border: 1px solid black;*/
}

.loader div {
    position: absolute;
    left: 7%;
    top: 9%;
    width: 15%;
    height: 15%;
    /* Caution, the container must always have a square ratio */
    border-radius: 50%;
    background-color: #2c3e50;
    animation: rotate 1.3s infinite cubic-bezier(0.7, 0.3, 0.3, 0.7);
    transform: rotateZ(50deg);
    /* Starts from the middle */
    transform-origin: 275% 275%;
}

.loader div:nth-child(2) {
    animation-delay: 0.17s;
}

.loader div:nth-child(3) {
    animation-delay: 0.34s;
}

.loader div:nth-child(4) {
    animation-delay: 0.51s;
}

.loader div:nth-child(5) {
    animation-delay: 0.68s;
}

@keyframes rotate {
    to {
        transform: rotateZ(410deg);
    }
    /* If it starts with 50deg, a complete turn ends with 410deg */
}
</style>