<template>
    <Icon icon="vk"/>
</template>

<script>
import Icon from './Icon'

export default {
    name: 'IconVk',

    components: {
        Icon
    }
}
</script>