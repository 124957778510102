export const libraryArtistRoutes = [
    {
        path: '/library/artist',
        name: 'LibraryArtist',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistView.vue'),
    },
    {
        path: '/library/artist/create',
        name: 'LibraryArtistCreate',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistCreateView.vue'),
    },
    {
        path: '/library/artist/delete',
        name: 'LibraryArtistDelete',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistDeleteView.vue'),
    },
    {
        path: '/library/artist/publication-rules',
        name: 'LibraryArtistPublicationRules',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistRulesView.vue'),
    },
    {
        path: '/library/artist/create-or-edit-product',
        name: 'LibraryArtistCreateOrEditProduct',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistCreateOrEditProductView.vue'),
    },
    {
        path: '/library/artist/delete-product',
        name: 'LibraryArtistDeleteProduct',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistDeleteProductView.vue'),
    },
    {
        path: '/library/artist/payment',
        name: 'LibraryArtistPayment',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistPaymentView.vue'),
    },
    {
        path: '/library/artist/agent-report',
        name: 'LibraryArtistAgentReport',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistAgentReportView.vue'),
    },
    {
        path: '/library/artist/deals',
        name: 'LibraryArtistDeals',
        meta: {
            layout: 'Main',
            title: 'Библиотека знаний'
        },
        component: () => import('../views/main/library/artist/ArtistDealsView.vue'),
    },
]