import { mutation } from '../scripts/scripts'

export default {
    state: {
        cursorArtists: null,
        cursorArtistDeleteBids: null,

        cursorAgentReports: null,

        cursorItems: null,
        cursorSearchItems: null,
        cursorPortfolioItems: null,

        cursorMessages: null,

        cursorNotifications: null,

        cursorCustomerDeals: null,
        cursorArtistDeals: null,

        cursorTags: null,

        cursorTransactions: null,

        cursorMessagesTicket: null,
        cursorTickets: null,

        cursorUsers: null,
        cursorUsersPersonalData: null,

        cursorFollowers: null,
        cursorFollows: null
    },

    mutations: {
        setCursorArtists: mutation('cursorArtists'),
        setCursorArtistDeleteBids: mutation('cursorArtistDeleteBids'),

        setCursorAgentReports: mutation('cursorAgentReports'),

        setCursorItems: mutation('cursorItems'),
        setCursorSearchItems: mutation('cursorSearchItems'),
        setCursorPortfolioItems: mutation('cursorPortfolioItems'),

        setCursorUsersPersonalData: mutation('cursorUsersPersonalData'),

        setCursorMessages: mutation('cursorMessages'),

        setCursorNotifications: mutation('cursorNotifications'),

        setCursorCustomerDeals: mutation('cursorCustomerDeals'),
        setCursorArtistDeals: mutation('cursorArtistDeals'),

        setCursorTags: mutation('cursorTags'),

        setCursorTransactions: mutation('cursorTransactions'),

        setCursorMessagesTicket: mutation('cursorMessagesTicket'),
        setCursorTickets: mutation('cursorTickets'),

        setCursorUsers: mutation('cursorUsers'),

        setCursorFollowers: mutation('cursorFollowers'),
        setCursorFollows: mutation('cursorFollows')
    },

    getters: {
        getCursorArtists: state => state.cursorArtists,
        getCursorArtistDeleteBids: state => state.cursorArtistDeleteBids,

        getCursorAgentReports: state => state.cursorAgentReports,

        getCursorItems: state => state.cursorItems,
        getCursorSearchItems: state => state.cursorSearchItems,
        getCursorPortfolioItems: state => state.cursorPortfolioItems,

        getCursorMessages: state => state.cursorMessages,

        getCursorNotifications: state => state.cursorNotifications,

        getCursorCustomerDeals: state => state.cursorCustomerDeals,
        getCursorArtistDeals: state => state.cursorArtistDeals,

        getCursorTags: state => state.cursorTags,

        getCursorTransactions: state => state.cursorTransactions,

        getCursorMessagesTicket: state => state.cursorMessagesTicket,
        getCursorTickets: state => state.cursorTickets,

        getCursorUsers: state => state.cursorUsers,
        getCursorUsersPersonalData: state => state.cursorUsersPersonalData,

        getCursorFollowers: state => state.cursorFollowers,
        getCursorFollows: state => state.cursorFollows
    }
}
