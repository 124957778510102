<script>
export default {
    name: 'DashboardHeader'
}
</script>

<template>
    <div class="dashboard__header">

    </div>
</template>