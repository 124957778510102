<template>
    <HeaderMain/>

    <FlashMessage/>

    <div class="app__container">
        <router-view/>
    </div>

    <Cookie/>

    <Footer/>
</template>

<script>
import FlashMessage from '../components/modals/FlashMessage.vue'
import Footer from '../components/Main/Footer.vue'
import HeaderMain from '../components/Main/HeaderMain.vue'
import Cookie from '../components/Main/Cookie.vue';

export default {
    name: 'HomeLayout',

    components: {
        Cookie,
        HeaderMain,
        Footer,
        FlashMessage
    },

    props: {
        usePageLayout: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.app__container {
    display: block;
}
</style>