import { SERVER_API } from './static'

import axios from 'axios'
import cookie from 'vue-cookies'

export const get = function (uri, params = {}, customHeaders = {}, dashboard = false)
{
    const headers = Object.assign(customHeaders, getAuthHeader(dashboard))

    return axios.get(SERVER_API + uri, {
        headers,
        params: params
    })
}

export const post = function (uri, data = {}, customHeaders = {}, dashboard = false)
{
    const headers = Object.assign(customHeaders, getAuthHeader(dashboard))

    return axios.post(SERVER_API + uri, data, {
        headers
    })
}

export const put = function (uri, data, customHeaders = {}, dashboard = false) {
    const headers = Object.assign(customHeaders, getAuthHeader(dashboard))

    return axios.put(SERVER_API + uri, data, {
        headers
    })
}

export const del = function (uri, data = {}, customHeaders = {}, dashboard = false)
{
    const headers = Object.assign(customHeaders, getAuthHeader(dashboard))

    return axios.delete(SERVER_API + uri, {
        headers
    })
}

function getAuthHeader (dashboard) {
    let authorization = {}

    if (dashboard) {
        authorization = cookie.get('authTokenModerator') ? { Authorization: 'Bearer ' + cookie.get('authTokenModerator') } : {}
    } else {
        authorization = cookie.get('authToken') ? { Authorization: 'Bearer ' + cookie.get('authToken') } : {}
    }

    return authorization
}
