<template>
    <div
        v-if="notifications?.length"
        class="grid__gap__30"
    >
        <div v-for="notification in notifications"
             :key="notification.id">
            <NotificationCard
                v-model="openedModal"
                :type-notification="typeNotifications"
                :notification="notification"
            />
        </div>
    </div>

    <div v-else class="content__text__center">
       <span>
            У вас пока нет уведомлений
       </span>
    </div>
</template>

<script>
import NotificationCard from '../Cards/NotificationCard.vue'

export default {
    name: 'Notifications',

    components: {
        NotificationCard
    },

    props: {
        modelValue: {
            type: Boolean
        },

        notifications: {
            required: true,
            type: Array
        },

        typeNotifications: {
            type: String,
            default: () => 'user'
        }
    },

    data () {
        return {
            openedModal: this.modelValue
        }
    },

    watch: {
        openedModal () {
            this.$emit('update:modelValue', this.openedModal)
        }
    }
}
</script>