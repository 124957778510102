<template>
    <ValidateErrorsForm :errors="errors"/>

    <input
        :disabled="disabled"
        :autocomplete="autocomplete ? typeInput : 'new-' + typeInput"
        :type="typeInput"
        :placeholder="placeholderInput"
        :value="modelValue ? modelValue : value"
        :minlength="minLength"
        :maxlength="maxLength"
        :class="{'error': errors.length}"
        @input="updateValue"
        @keyup.enter="$emit('wasUp')"
        @keydown="$emit('wasDown')"
    >

    <span v-if="showCountChars" class="count__chars">
        {{
            (modelValue ? modelValue.length : value.length) + ' из ' + maxLength
        }}
    </span>
</template>

<script>
import ValidateErrorsForm from '../../forms/ValidateErrorsForm'

export default {
    name: 'InputText',
    components: {
        ValidateErrorsForm
    },
    props: {
        typeInput: {
            type: String,
            required: true
        },
        placeholderInput: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String
        },
        defaultValue: {
            type: String,
            default: ''
        },
        minLength: {
            type: Number
        },
        maxLength: {
            type: Number
        },
        showCountChars: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: Boolean,
            default: true
        },
        errors: {
            type: Array,
            default: function () {
                return []
            }
        }
    },

    data() {
      return {
          value: this.defaultValue
      }
    },

    methods: {
        updateValue (event) {
            this.value = ''

            this.$emit('update:modelValue', event.target.value)
        }
    }
}
</script>

<style scoped>

</style>
