import { mutation } from '../scripts/scripts'

export default {
    state: {
        loaderAuthUser: false,

        loaderItem: false,

        loaderItems: false,
        loaderOtherUserItems: false,
        loaderSearchItems: false,
        loaderPortfolioItems: false,
        loaderNewItems: false,
        loaderDiscountItems: false,

        loaderMessages: false,

        loaderNotifications: false,

        loaderDeal: false,
        loaderCustomerDeals: false,
        loaderArtistDeals: false,

        loaderTransactions: false,

        loaderUser: false,
        loaderUsers: false,

        loaderTicket: false,
        loaderMessagesTicket: false,
        loaderTickets: false,

        loaderFailed: null
    },

    mutations: {
        setLoaderAuthUser: mutation('loaderAuthUser'),

        setLoaderItem: mutation('loaderItem'),

        setLoaderItems: mutation('loaderItems'),
        setLoaderOtherUserItems: mutation('loaderOtherUserItems'),
        setLoaderSearchItems: mutation('loaderSearchItems'),
        setLoaderPortfolioItems: mutation('loaderPortfolioItems'),
        setLoaderNewItems: mutation('loaderNewItems'),
        setLoaderDiscountItems: mutation('loaderDiscountItems'),

        setLoaderMessages: mutation('loaderMessages'),

        setLoaderNotifications: mutation('loaderNotifications'),

        setLoaderDeal: mutation('loaderDeal'),
        setLoaderCustomerDeals: mutation('loaderCustomerDeals'),
        setLoaderArtistDeals: mutation('loaderArtistDeals'),

        setLoaderTransactions: mutation('loaderTransactions'),

        setLoaderUser: mutation('loaderUser'),
        setLoaderUsers: mutation('loaderUsers'),

        setLoaderTicket: mutation('loaderTicket'),
        setLoaderMessagesTicket: mutation('loaderMessagesTicket'),
        setLoaderTickets: mutation('loaderTickets'),

        setLoaderFailed: mutation('loaderFailed')
    },

    getters: {
        getLoaderAuthUser: state => state.loaderAuthUser,

        getLoaderItem: state => state.loaderItem,

        getLoaderItems: state => state.loaderItems,
        getLoaderOtherUserItems: state => state.loaderOtherUserItems,
        getLoaderSearchItems: state => state.loaderSearchItems,
        getLoaderPortfolioItems: state => state.loaderPortfolioItems,
        getLoaderNewItems: state => state.loaderNewItems,
        getLoaderDiscountItems: state => state.loaderDiscountItems,

        getLoaderMessages: state => state.loaderMessages,

        getLoaderNotifications: state => state.loaderNotifications,

        getLoaderDeal: state => state.loaderDeal,
        getLoaderCustomerDeals: state => state.loaderCustomerDeals,
        getLoaderArtistDeals: state => state.loaderArtistDeals,

        getLoaderTransactions: state => state.loaderTransactions,

        getLoaderUser: state => state.loaderUser,
        getLoaderUsers: state => state.loaderUsers,

        getLoaderTicket: state => state.loaderTicket,
        getLoaderMessagesTicket: state => state.loaderMessagesTicket,
        getLoaderTickets: state => state.loaderTickets,

        getLoaderFailed: state => state.loaderFailed
    }
}
