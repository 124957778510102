import { del, get, post } from '../../scripts/ajax'
import { mutation } from '../../scripts/scripts'

import router from '@/router'

export default {
    state: {
        personal: null,
        requisite: null
    },

    mutations: {
        setPersonal: mutation('personal'),
        setRequisite: mutation('requisite'),

        clearPersonal: state => state.personal = null,
        clearRequisite: state => state.requisite = null,
    },

    getters: {
        getPersonal: state => state.personal,
        getRequisite: state => state.requisite
    },

    actions: {
        async loadPersonal (ctx) {
            return new Promise((resolve, reject) => {
                get('/profile/personal')
                    .then(response => {
                        ctx.commit('setPersonal', response.data.personal)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async loadRequisite (ctx) {
            return new Promise((resolve, reject) => {
                get('/profile/requisite')
                    .then(response => {
                        ctx.commit('setRequisite', response.data.requisite)

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async updateProfile (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/profile', data)
                    .then(response => {
                        ctx.commit('setAuthUser', response.data.user)

                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setValidateErrors', [])

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)
                        ctx.commit('setValidateErrors', error.response.data.errors)

                        reject(error)
                    })
            })
        },

        async updateRequisite (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/profile/requisite', data)
                    .then(response => {
                        ctx.commit('setRequisite', response.data.requisite)

                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setValidateErrors', [])

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)
                        ctx.commit('setValidateErrors', error.response.data.errors)

                        reject(error)
                    })
            })
        },

        async updatePersonalData (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/profile/personal', data)
                    .then(response => {
                        ctx.commit('setPersonal', response.data.personal)

                        ctx.commit('setSuccessMessage', response.data.message)
                        ctx.commit('setValidateErrors', [])

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)
                        ctx.commit('setValidateErrors', error.response.data.errors)

                        reject(error)
                    })
            })
        },

        async deleteProfile (ctx) {
            return new Promise((resolve, reject) => {
                del('/profile')
                    .then(response => {
                        ctx.commit('setAuthUser', response.data.user)

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async agreeOffer (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/profile/agree', data)
                    .then(response => {
                        ctx.commit('setAuthUser', response.data.user)

                        resolve()
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async recoveryPassword (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/recovery-password', data)
                    .then(response => {
                        router.push('/')

                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
