import { get } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        tags: [],
        searchTags: []
    },

    mutations: {
        setTags: mutation('tags'),
        setSearchTags: mutationWithPaginate('searchTags'),

        clearTags: state => state.tags = [],
        clearSearchTags: state => state.searchTags = [],
    },

    getters: {
        getTags: state => state.tags,
        getSearchTags: state => state.searchTags
    },

    actions: {
        async searchTags (ctx, text) {
            get('/tags/search/' + text)
                .then(response => {
                    ctx.commit('setSearchTags', {
                        items: response.data.paginator.data,
                        paginate: false
                    })
                    ctx.commit('setCursorTags', response.data.paginator.next_cursor)
                })
        },

        async loadSearchTags (ctx, data) {
            get('/tags/search/', data)
                .then(response => {
                    ctx.commit('setSearchTags', {
                        items: response.data.paginator.data,
                        paginate: !!data.cursor
                    })
                    ctx.commit('setCursorTags', response.data.paginator.next_cursor)
                })
        },

        async loadTagsFromIds (ctx, requestParams) {
            get('/tags/find?' + requestParams)
                .then(response => {
                    ctx.commit('setTags', response.data.tags)
                })
                .catch(error => {
                    ctx.commit('setErrorMessage', error.response.data)
                })
        }
    }
}
