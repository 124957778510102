<template>
    <slot name="button" :clicked="open">
        <ButtonBlue @wasClicked="open">
            {{ actionButtonText }}
        </ButtonBlue>
    </slot>

    <transition name="fade">
        <Modal v-model="openedModal">
            <div class="modal__layout">
                <div class="modal__content container">

                    <div class="container__title modal__title">
                        <span>{{ formHeader }}</span>

                        <div class="icon__action">
                            <i class="icon__close" @click="open"> </i>
                        </div>
                    </div>

                    <div class="container__body grid__gap__30">
                        <div>
                            <div class="modal__info">
                                <slot name="info"></slot>
                            </div>

                            <slot></slot>
                        </div>

                        <slot name="form__buttons" :clicked="open">
                            <div class="form__input">
                                <div class="double__columns">
                                    <ButtonNone @wasClicked="open">Отменить</ButtonNone>
                                </div>
                            </div>
                        </slot>
                    </div>

                    <div class="container__footer"></div>
                </div>
            </div>
        </Modal>
    </transition>
</template>

<script>
import { mapMutations } from 'vuex'

import Modal from './Modal.vue'
import ButtonBlue from '../UI/buttons/ButtonBlue.vue'
import ButtonNone from '../UI/buttons/ButtonNone.vue'

export default {
    name: 'ModalForm',

    components: {
        ButtonNone,
        ButtonBlue,
        Modal
    },

    props: {
        actionButtonText: {
            type: String,
            required: true
        },

        formHeader: {
            type: String,
            required: true
        },

        // Закрыть модальное окно после выполнения какого-либо действия
        closeModal: {
            type: Boolean,
            default: false
        },

        // Открыть модальное окно после загрузки страницы
        showModal: {
            type: Boolean,
            default: false
        },

        cancelClose: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            openedModal: this.showModal
        }
    },

    watch: {
        closeModal () {
            if (this.closeModal) {
                this.openedModal = false
            }
        },

        showModal () {
            this.openedModal = this.showModal
        },

        openedModal () {
            if (this.openedModal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
    },

    methods: {
        ...mapMutations(['setValidateErrors']),

        open () {
            if (this.cancelClose) {
                return
            }

            this.openedModal = !this.openedModal

            if (this.openedModal) {
                this.setValidateErrors([])

                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        }
    },

    mounted () {
        document.addEventListener('keydown', (e) => {
            if (this.cancelClose) {
                return
            }

            if (e.key === 'Escape') {
                this.openedModal = false

                document.body.style.overflow = 'auto'
            }
        });
    },

    unmounted () {
        document.body.style.overflow = 'auto'
    }
}
</script>