<template>
    <Image
        :src="image?.path_thumb ?? ''"
        :alt="image?.id"
    />
</template>

<script>
import Image from './Image.vue'

export default {
    name: 'ThumbImage',

    components: {
        Image
    },

    props: {
        image: {
            type: [Object, null],
            required: true
        }
    }
}
</script>