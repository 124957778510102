<template>
    <div class="header">
        <div class="header__container">
            <div class="header__content">
                <router-link to="/">
                    <img
                        class="logo"
                        src="/images/logo.png"
                        alt="logo"
                    >
                </router-link>

                <div class="justify__content__right">
                    <div class="flex__row flex__grid__gap__30">
                        <ul v-if="!showMenuIcon" class="font-size__15 flex__row flex__grid__gap__30">
                            <li>
                                <router-link class="hover__link__primary" to="/catalog">
                                    Каталог
                                </router-link>
                            </li>

                            <li>
                                <router-link class="hover__link__primary" to="/artists">
                                    Художники
                                </router-link>
                            </li>
                        </ul>

                        <div class="header__navigate" v-if="authUser">

                            <DropdownMenu v-if="showMenuIcon"/>

                            <div v-else></div>

                            <router-link class="nav__icon" to='/search/items'>
                                <IconSearch/>
                            </router-link>

                            <NotificationSystemModal/>

                            <NotificationMessageModal/>

                            <DropdownUserMenu/>
                        </div>

                        <div class="header__navigate__auth " v-else>
                            <DropdownMenu v-if="showMenuIcon"/>

                            <div v-else></div>

                            <router-link class="nav__icon" to='/search/items'>
                                <IconSearch/>
                            </router-link>

                            <div class="font-size__15">
                                <LoginForm/>
                            </div>

                            <div class="font-size__15">
                                <RegisterForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LoginForm from '../Forms/Auth/LoginForm.vue'
import RegisterForm from '../Forms/Auth/RegisterForm.vue'
import IconSearch from '../UI/icons/IconSearch.vue'
import NotificationSystemModal from '../Modals/Notifications/NotificationSystemModal.vue'
import NotificationMessageModal from '../Modals/Notifications/NotificationMessageModal.vue'
import DropdownMenu from '../UI/dropdowns/DropdownMenu.vue'
import DropdownUserMenu from '../UI/dropdowns/DropdownUserMenu.vue'

export default {
    name: 'HeaderMain',
    components: {
        DropdownUserMenu,
        DropdownMenu,
        NotificationMessageModal,
        NotificationSystemModal,
        IconSearch,
        RegisterForm,
        LoginForm
    },

    computed: {
        ...mapGetters({
            authUser: 'getAuthUser'
        })
    },

    data () {
        return {
            showMenuIcon: false
        }
    },

    methods: {
        checkWidth (width) {
            window.addEventListener('resize', () => {
                this.showMenuIcon = window.innerWidth <= width
            })
        }
    },

    mounted () {
        this.checkWidth(980)

        this.showMenuIcon = window.innerWidth <= 980
    }
}
</script>

<style>
.header {
    z-index: 5;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;

    transition: none;
    display: grid;
    justify-items: center;
    justify-self: center;

    margin-bottom: 20px;

    height: 80px;
    background: var(--app-bg-dark);
}

.header__content {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    align-content: center;

    height: 80px;
}

.header__content li > a, .header__navigate > a, .header__navigate__auth > a {
    color: var(--app-color-white);
}
.header__content li > a:hover, .header__navigate__auth > a:hover {
    color: #c1dff7;
}

.header__content .nav__icon.active {
    color: var(--app-color-white);
}

.header__content a.button {
    color: var(--app-color-white);
}

.header__navigate__auth {
    display: grid;
    grid-template-columns: 40px 40px auto auto;
    justify-self: right;
    align-items: center;
    grid-gap: 10px;

    color: var(--app-color-white);
}

.header__navigate {
    display: grid;
    grid-template-columns: 40px 40px 40px 40px 40px;
    justify-self: right;
    align-items: center;
    grid-gap: 10px;

    color: var(--app-color-white);
}

.header__navigate .user__logo img {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 5px;
}

.header__navigate .nav__icon, .header__navigate__auth .nav__icon {
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 20px;
    height: 40px;
    border-radius: 5px;
}

.header__navigate .nav__icon:hover, .header__navigate__auth .nav__icon:hover {
    background: #4b5669;
}
.header__navigate .nav__icon.active, .header__navigate__auth .nav__icon.active {
    background: #4b5669;
}
</style>
