import { get, post } from '../../scripts/ajax'
import { mutation, mutationWithPaginate } from '../../scripts/scripts'

export default {
    state: {
        ticket: null,
        messagesTicket: [],

        tickets: []
    },

    mutations: {
        setTicket: mutation('ticket'),
        setMessagesTicket: (state, data) => {
            if (data.paginate) {
                state.messagesTicket.unshift(...data.items.reverse())
            } else {
                state.messagesTicket = data.items.reverse()
            }
        },
        setMessageTicket: (state, message) => {
            state.messagesTicket.push(message)
        },
        setTickets: mutationWithPaginate('tickets'),

        clearTicket: state => state.ticket = null,
        clearMessagesTicket: state => state.messagesTicket = [],

        clearTickets: state => state.tickets = []
    },

    getters: {
        getTicket: state => state.ticket,
        getMessagesTicket: state => state.messagesTicket,
        getTickets: state => state.tickets
    },

    actions: {
        async loadTicket (ctx, data) {
            ctx.commit('setLoaderTicket', true)

            return new Promise((resolve, reject) => {
                get('/support/tickets/' + data.ticket_id, data.params)
                    .then(response => {
                        const ticket = response.data.ticket

                        if (ticket) {
                            ctx.commit('setTicket', response.data.ticket)
                        } else {
                            ctx.commit('loadErrorPageMutation', 404)
                        }

                        ctx.commit('setLoaderTicket', false)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadMessagesTicket (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderMessagesTicket', true)
            }

            return new Promise((resolve, reject) => {
                get('/support/tickets/' + data.ticket_id + '/messages', data.params)
                    .then(response => {
                        ctx.commit('setMessagesTicket', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })

                        ctx.commit('setLoaderMessagesTicket', false)
                        ctx.commit('setCursorMessagesTicket', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadMessagesTicketAdmin (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderMessagesTicket', true)
            }

            return new Promise((resolve, reject) => {
                get('/admin/support/tickets/' + data.ticket_id + '/messages', data.params)
                    .then(response => {
                        ctx.commit('setMessagesTicket', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })

                        ctx.commit('setLoaderMessagesTicket', false)
                        ctx.commit('setCursorMessagesTicket', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async loadTickets (ctx, data) {
            if (!data.cursor) {
                ctx.commit('setLoaderTickets', true)
            }

            return new Promise((resolve, reject) => {
                get('/support/tickets', data.params)
                    .then(response => {
                        ctx.commit('setTickets', {
                            items: response.data.paginator.data,
                            paginate: !!data.cursor
                        })

                        ctx.commit('setLoaderTickets', false)
                        ctx.commit('setCursorTickets', response.data.paginator.next_cursor)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setLoaderFailed', error.response.data.message)

                        reject(error)
                    })
            })
        },


        async createTicket (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/support/tickets', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setTicket', response.data.ticket)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async endedTicket (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/support/tickets/' + data.ticket_id + '/close', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setTicket', response.data.ticket)
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async createMessageTicket (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/support/tickets/' + data.ticket_id + '/messages', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setMessageTicket', response.data.message)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        },

        async createMessageTicketAdmin (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/admin/support/tickets/' + data.ticket_id + '/messages', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setMessageTicket', response.data.message)

                        resolve()
                    })
                    .catch((error) => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
