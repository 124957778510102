import { post } from '../../scripts/ajax'

export default {
    state: {

    },

    mutations: {

    },

    getters: {

    },

    actions: {
        async createReport (ctx, data) {
            return new Promise((resolve, reject) => {
                post('/reports', data)
                    .then(response => {
                        ctx.commit('setValidateErrors', [])
                        ctx.commit('setSuccessMessage', response.data.message)

                        resolve(response)
                    })
                    .catch(error => {
                        ctx.commit('setValidateErrors', error.response.data.errors)
                        ctx.commit('setErrorMessage', error.response.data.message)

                        reject(error)
                    })
            })
        }
    }
}
